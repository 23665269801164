import { Divider, Grid, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import styles from '../ContactDataForm/ContactDataForm.module.scss';
import { profilePersonalDataKeys } from '../ProfilePersonalData';

const SocialMediaForm = () => {
  const socialMediaAccounts = useSelector(selectLoggedInCreator)?.socialMediaAccounts;
  const startAdornments = {
    InputProps: {
      startAdornment: <InputAdornment position="start">@</InputAdornment>,
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3 className={styles.heading}>4. Social Media</h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.instagram}
          label="Instagram"
          value={socialMediaAccounts?.instagram}
          muiProps={startAdornments}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.tiktok}
          label="TikTok"
          value={socialMediaAccounts?.tiktok}
          muiProps={startAdornments}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.youtube}
          label="Youtube"
          value={socialMediaAccounts?.youtube}
          muiProps={{
            InputProps: {
              startAdornment: <InputAdornment position="start">youtube.com/</InputAdornment>,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.facebook}
          label="Facebook"
          value={socialMediaAccounts?.facebook}
          muiProps={startAdornments}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.snapchat}
          label="Snapchat"
          value={socialMediaAccounts?.snapchat}
          muiProps={startAdornments}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.pinterest}
          label="Pinterest"
          value={socialMediaAccounts?.pinterest}
          muiProps={startAdornments}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default SocialMediaForm;
