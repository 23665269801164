import { Button } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../../state/authSlice';
import styles from './AccessData.module.scss';

const AccessData = () => {
  const dispatch = useDispatch();
  const goToLogin = () => dispatch(logout());

  return (
    <>
      <h3 className={styles.header}>Zugangsdaten</h3>
      <span>
        Um das Passwort zu ändern, klicke bitte beim Login auf &quot;Forgot your password?&quot;. Du
        bekommst dann eine E-Mail mit einem Link, mit dem Du ein neues Passwort erstellen kannst.
      </span>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button onClick={goToLogin} className={styles.button}>
          <Typography fontWeight={700}>Abmelden & Login öffnen</Typography>
        </Button>
      </Box>
    </>
  );
};

export default AccessData;
