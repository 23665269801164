import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import { ProductTileInfo } from '../../model/productTileInfo';
import ProductSelectionItem from './ProductSelectionItem/ProductSelectionItem';
import styles from './ProductsSelection.module.scss';

interface Props {
  product: ProductTileInfo;
  handleSelect: (product: ProductTileInfo) => void;
  handleRemove: (product: ProductTileInfo) => void;
  selectedProducts: ProductTileInfo[];
}
function ProductWithSelector({ product, handleSelect, handleRemove, selectedProducts }: Props) {
  const isSelected = !!selectedProducts.find(e => e.baseProductNo === product.baseProductNo);
  const isDisabled = product.outOfStock;

  return (
    <Box
      className={classNames(styles.product, isDisabled && styles.disabled_product)}
      onClick={() => !isDisabled && (isSelected ? handleRemove(product) : handleSelect(product))}
    >
      <ProductSelectionItem product={product} direction={'row'} />
      {!isDisabled && (
        <Box className={styles.action}>
          {!isSelected ? (
            <Button type={EButtonType.BLANK} className={styles.add_btn} size={EButtonSize.TINY}>
              <Icon
                className={styles.add_icon}
                icon={Icons.plusIcon}
                dataTestId="AddCircleOutlineOutlinedIcon"
              />
            </Button>
          ) : (
            <Button type={EButtonType.BLANK} size={EButtonSize.TINY} className={styles.checked_btn}>
              <Icon icon={Icons.checkIcon} dataTestId="CheckCircleIcon" className={styles.icon} />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ProductWithSelector;
