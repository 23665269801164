import LiveTvIcon from '@mui/icons-material/LiveTv';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ResponsiveDetailsRow from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import formatTime from '../../../../utils/formatters/formatTime/formatTime';
import { EUROPE_BERLIN_TIMEZONE } from '../../../constants';
import { ShowDetails } from '../../../model/shows';
import StreamedByInfo from '../StreamedBy/StreamedByInfo';
import styles from './ShowInfo.module.scss';

interface ShowInfoProps {
  showDetails: ShowDetails;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const SHOW_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const formatDateIfProvided = (dateAsString: string | undefined): string =>
  dateAsString ? dayjs(dateAsString).tz(EUROPE_BERLIN_TIMEZONE).format(SHOW_DATE_FORMAT) : '-';

const ShowInfo = ({ showDetails }: ShowInfoProps) => {
  return (
    <div data-testid={'show-info'}>
      <Box className={styles.show_info_header}>
        <LiveTvIcon className={styles.show_icon} />
        <Typography fontWeight={'700'} mt={'4px'} sx={{ fontSize: '18px' }}>
          Stream
        </Typography>
      </Box>
      <StreamedByInfo showId={showDetails.id} streamedBy={showDetails.streamedBy} />
      <ResponsiveDetailsRow label="ID" value={showDetails.id} withCopyIcon />
      <ResponsiveDetailsRow label="Titel" value={showDetails.title} />
      <ResponsiveDetailsRow label="Geplant" value={formatDateIfProvided(showDetails.scheduledAt)} />
      <ResponsiveDetailsRow label="Startzeit" value={formatDateIfProvided(showDetails.startedAt)} />
      <ResponsiveDetailsRow label="Endzeit" value={formatDateIfProvided(showDetails.endedAt)} />
      <ResponsiveDetailsRow
        label="Dauer"
        value={showDetails.durationInSeconds ? formatTime(showDetails.durationInSeconds) : '-'}
      />
    </div>
  );
};

export default ShowInfo;
