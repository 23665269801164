import { Drawer as MaterialDrawer, Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      top: 'unset',
      'z-index': 98,
    },
  })
);

interface Props {
  navItems: JSX.Element;
}

const Drawer = ({ navItems }: Props) => {
  const classes = useStyles();

  return (
    <nav className={classes.drawer} aria-label="side navigation">
      <MaterialDrawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <Divider />
        {navItems}
      </MaterialDrawer>
    </nav>
  );
};

export default Drawer;
