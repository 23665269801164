import { isMobileBreakPoint } from '../../common/mediaQueries';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';

const Navbar = () => {
  const isMobile = isMobileBreakPoint();

  return isMobile ? <MobileNavbar /> : <DesktopNavbar />;
};

export default Navbar;
