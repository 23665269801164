import LockIcon from '@mui/icons-material/Lock';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useState } from 'react';

interface MobileProfileLockInfoTooltipProps {
  title: string;
}

const MobileProfileLockInfoTooltip = ({ title }: MobileProfileLockInfoTooltipProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        onClose={() => setOpen(false)}
        open={open}
        title={title}
        placement="bottom-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton data-testid="mobile-lock-icon" onClick={() => setOpen(!open)}>
          <LockIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default MobileProfileLockInfoTooltip;
