type PostFile = {
  type: 'image' | 'video';
  extension: string;
};

export const extractMediaType = (dataUri: string): PostFile | null => {
  const mediaTypePattern = /^data:([^;]*);base64,.*/;
  const match = dataUri.match(mediaTypePattern);
  if (match) {
    const [type, extension] = match[1].split('/');
    return { type, extension } as PostFile;
  }
  return null;
};
