import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardMedia, IconButton, Paper, Stack, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../assets';
import { selectUserIsClassicStreamer } from '../../../auth/state/authSlice';
import CropModal from '../../../components/CropModal/CropModal';
import Icon from '../../../components/Icon/Icon';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import { CreatorImageType } from '../../../creators/model/creator';
import useValidateAndCropImage from '../../../shared/images/useValidateAndCropImage';
import { Aspect, CropSource } from '../../../utils/images/cropImageFns';
import { getUrlWithImageManagerParams } from '../../../utils/media';
import { ImageType, ShowCoverSelectionMetadata } from '../../model/shows';
import styles from './ShowCoverSelection.module.scss';

export interface ShowCoverSelectionProps {
  fieldName: string;
  imageType: ImageType | CreatorImageType;
  defaultImage?: string;
  metadata: ShowCoverSelectionMetadata;
  onChange?: (value?: string) => void;
  hasError?: boolean;
}

const ShowCoverSelection = (props: ShowCoverSelectionProps) => {
  const validateAndCrop = useValidateAndCropImage();
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const [coverImg, setCoverImg] = useState<string | null>(null);
  const [croppedImg, setCroppedImg] = useState<string | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const isClassicStreamer = useSelector(selectUserIsClassicStreamer);
  const isClassicRationEnabled = featureFlagValue(AvailableFlag.isClassicNewRatioEnabled);

  useEffect(() => {
    !!props.defaultImage && setCroppedImg(getUrlWithImageManagerParams(props.defaultImage));
  }, [props.defaultImage]);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const image: string | null = await validateAndCrop(event);
    if (image) {
      setCoverImg(image);
    }
  };

  const onSaveHandler = (image: Blob) => {
    const imageURL = URL.createObjectURL(image);
    setCroppedImg(imageURL);
    handleFileChange(image.type.substring(6), true, imageURL);
    setCoverImg(null);
  };

  const handleCancelCrop = () => {
    setCoverImg(null);
    handleFileChange('', false, undefined);
  };

  const handleRemoveImage = () => {
    setCoverImg(null);
    setCroppedImg(null);
    handleFileChange('', false, undefined);
  };

  const handleFileChange = (imageExtension: string, imageChanged: boolean, imageUrl?: string) => {
    setFieldValue(
      props.fieldName,
      {
        imageType: props.imageType,
        fileExtension: imageExtension,
        imageUrl: imageUrl,
        changed: imageChanged,
      },
      true
    );
    setFieldTouched(props.fieldName, true);
    props.onChange && props.onChange(imageUrl);
  };

  const cardClassName = cx({
    [styles.cover_card]: !props.hasError,
    [styles.error_cover_card]: props.hasError,
  });
  const textClassName = cx({
    [styles.error_secondary_text]: props.hasError,
  });
  const renderCoverImageCard = (hasError?: boolean) => (
    <Card variant="outlined" className={cardClassName}>
      {croppedImg ? (
        <CardMedia
          component="img"
          className={styles.cover_selected_img}
          image={croppedImg}
          data-testid="displayed-cropped-image"
        />
      ) : (
        <Paper className={styles.card} elevation={0} data-testid="default-image">
          <IconButton>
            <CalendarTodayIcon className={textClassName} />
          </IconButton>
          <Typography variant="body1" className={textClassName}>
            {t.creators.post['Upload image']}
          </Typography>
          <Typography
            variant="body1"
            className={hasError ? styles.error_secondary_text : styles.secondary_text}
          >
            {t.creators.show.imageFormat}
          </Typography>
        </Paper>
      )}
      <input
        className={styles.img_input}
        type="file"
        onChange={event => handleChange(event)}
        ref={hiddenFileInput}
        onClick={event => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
        data-testid="select-cover-input"
      />
      <Stack direction="row" spacing={2} className={styles.floating_stack}>
        {croppedImg && !props.defaultImage && (
          <IconButton
            onClick={handleRemoveImage}
            className={styles.remove_button}
            size="medium"
            data-testid="remove-image-button"
          >
            <DeleteIcon color="error" />
          </IconButton>
        )}
        <IconButton
          onClick={handleClick}
          className={cx(styles.floating_button, { [styles.edit]: !!croppedImg })}
          size="medium"
        >
          {croppedImg ? <EditIcon /> : <Icon dataTestId="upload-icon" icon={Icons.uploadIcon} />}
        </IconButton>
      </Stack>
    </Card>
  );

  const renderTitles = () => (
    <>
      {props.metadata.title && (
        <MenuItem className={styles.menu_item}>
          <ListItemIcon>
            <Icon dataTestId="video-camera-icon" icon={Icons.videoCamera} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" fontWeight="bold">
              {props.metadata.title}
            </Typography>
          </ListItemText>
        </MenuItem>
      )}
      {props.metadata.subTitle && (
        <Typography component="div" className={styles.secondary_text}>
          {props.metadata.subTitle}
        </Typography>
      )}
    </>
  );

  const renderCroppedImage = (coverImg: string) => {
    const aspect =
      isClassicRationEnabled && isClassicStreamer ? Aspect.RATIO_4_5 : Aspect.RATIO_9_16;
    return (
      <CropModal
        handleCancelCrop={handleCancelCrop}
        onSaveHandler={onSaveHandler}
        imageUrl={coverImg}
        aspect={aspect}
        cropSource={CropSource.STREAM}
        title="Vorschaubild hochladen"
        description="Dieses Bild ist öffentlich sichtbar."
      />
    );
  };

  return (
    <>
      {props.metadata.displayTitles && renderTitles()}
      <Box className={styles.show_cover}>
        {coverImg && renderCroppedImage(coverImg)}
        {renderCoverImageCard(props.hasError)}
      </Box>
    </>
  );
};

export default ShowCoverSelection;
