import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../../app/store';
import { Audience, PageableResponse, QueryParams } from '../../common/types';
import { DateRangeWithSelectedFilter } from '../../components/DateFilter/dateFilterTypes';
import { Pageable } from '../../creators/components/DataGridTable/ServerSideDataGrid';
import { TableFilter } from '../../creators/state/creatorsSlice';
import {
  PostStatusRequestResponse,
  PostUpdatePayload,
  PostWithStats,
  StreamerPostResponse,
  StreamerPostsResponse,
} from '../api/postsRequestResponse';
import { Post, PostData } from '../model/post';

export const createNewPost = createAction<PostData>('posts/createNewPost');
export const fetchStreamerPosts = createAction('posts/fetchStreamerPosts');
export const fetchStreamerPostsStats = createAction('posts/fetchStreamerPostsStats');
export const changePostStatus = createAction<PostStatusRequestResponse>('posts/changePostStatus');
export const fetchAdminPostsOverview = createAction<QueryParams>('posts/fetchAdminPostsOverview');
export const fetchStreamerPost = createAction<string>('posts/fetchStreamerPost');
export const updatePost = createAction<PostUpdatePayload>('posts/updatePost');

export interface CreatePost {
  loading: boolean;
}

export interface StreamerPosts extends PageableResponse {
  loading: boolean;
  pageable: Pageable;
  posts: PostWithStats[];
}

export interface PostsStats {
  loading: boolean;
  data?: PostsStatsData;
}

export interface PostsStatsData {
  totalLikes: number;
  totalComments: number;
  totalFollowers: number;
  overallRevenue: number;
}

export interface AdminPostsOverview extends PageableResponse {
  loading: boolean;
  queryParams: QueryParams;
  tableFilter?: TableFilter;
  dateFilter?: DateRangeWithSelectedFilter;
  audienceFilter?: Audience[];
  posts: Post[];
}

export interface PostsState {
  createPost: CreatePost;
  streamerPosts: StreamerPosts;
  postsStats: PostsStats;
  postStatus: {
    loading: boolean;
  };
  adminPostsOverview: AdminPostsOverview;
  post?: StreamerPostResponse;
  loading: boolean;
}

export const postsInitialState: PostsState = {
  createPost: {
    loading: false,
  },
  postStatus: {
    loading: false,
  },
  streamerPosts: {
    loading: false,
    pageable: {
      page: 0,
      pageSize: 10,
      sortField: 'createdAt',
      sortOrder: 'desc',
    },
    posts: [],
    hits: 0,
    pages: 0,
  },
  postsStats: {
    loading: false,
  },
  adminPostsOverview: {
    hits: 0,
    pages: 0,
    loading: false,
    queryParams: {
      pageSize: 10,
      pageNumber: 0,
      sortField: 'createdAt',
      sortOrder: 'desc',
      from: dayjs().format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD'),
      audience: [Audience.HELLO],
    },
    tableFilter: {
      pagination: {
        pageSize: 10,
      },
    },
    posts: [],
  },
  post: undefined,
  loading: false,
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState: postsInitialState,
  reducers: {
    setCreatingPostLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.createPost.loading = action.payload;
    },
    setStreamerPosts: (state: PostsState, action: PayloadAction<StreamerPostsResponse>) => {
      state.streamerPosts = {
        ...state.streamerPosts,
        loading: false,
        hits: action.payload.hits,
        pages: action.payload.pages,
        posts: action.payload.posts,
      };
    },
    setStreamerPostsLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.streamerPosts.loading = action.payload;
    },
    setStreamerPostsPageable: (state: PostsState, action: PayloadAction<Pageable>) => {
      state.streamerPosts.loading = false;
      state.streamerPosts.pageable = action.payload;
    },
    setStreamerPostsStatsLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.postsStats.loading = action.payload;
    },
    setStreamerPostsStats: (state: PostsState, action: PayloadAction<PostsStatsData>) => {
      state.postsStats = {
        loading: false,
        data: action.payload,
      };
    },
    setPostStatusLoading: (state: PostsState, action: PayloadAction<boolean>) => {
      state.postStatus.loading = action.payload;
    },
    setStreamerPostStatus: (
      state: PostsState,
      action: PayloadAction<PostStatusRequestResponse>
    ) => {
      state.streamerPosts.posts = state.streamerPosts.posts.map(post =>
        post.id === action.payload.postId ? { ...post, status: action.payload.postStatus } : post
      );
    },
    setAdminPostStatus: (state: PostsState, action: PayloadAction<PostStatusRequestResponse>) => {
      state.adminPostsOverview.posts = state.adminPostsOverview.posts.map(post =>
        post.id === action.payload.postId ? { ...post, status: action.payload.postStatus } : post
      );
    },
    setAdminPostsOverview: (state, action: PayloadAction<Partial<AdminPostsOverview>>) => {
      state.adminPostsOverview = {
        ...state.adminPostsOverview,
        ...action.payload,
      };
    },
    setPost: (state, action: PayloadAction<StreamerPostResponse>) => {
      state.post = action.payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStreamerPost, state => {
      state.loading = true;
    });
  },
});

export const {
  setCreatingPostLoading,
  setStreamerPosts,
  setStreamerPostsLoading,
  setStreamerPostsPageable,
  setStreamerPostsStatsLoading,
  setStreamerPostsStats,
  setPostStatusLoading,
  setStreamerPostStatus,
  setAdminPostsOverview,
  setAdminPostStatus,
  setPost,
} = postsSlice.actions;

export const selectCreatePost = (state: RootState) => state.posts.createPost;

export const selectStreamerPosts = (state: RootState) => state.posts.streamerPosts;
export const selectStreamerPostsPageable = (state: RootState) => state.posts.streamerPosts.pageable;
export const selectStreamerPostsStats = (state: RootState) => state.posts.postsStats;

export const selectPostStatusLoading = (state: RootState) => state.posts.postStatus.loading;
export const selectAdminPostsOverview = (state: RootState) => state.posts.adminPostsOverview;
export const selectStreamerPost = (state: RootState) => state.posts.post;
export const selectPostIsBeingLoaded = (state: RootState) => state.posts.loading;

export default postsSlice.reducer;
