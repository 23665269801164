import { Hit } from '../model/hit';
import { ProductTileInfo } from '../model/productTileInfo';
import { determineIfProductHasStartingPrice, getLowestPriceVariant } from './priceUtils';

export const isProductNew = (newInShopDate?: string): boolean => {
  const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
  const MAX_AGE = 15;
  const dayBeforeMaxAge = new Date().setDate(new Date().getDate() - MAX_AGE - 1);
  const newInShopTime = newInShopDate
    ? Date.parse(newInShopDate)
    : Date.parse(String(dayBeforeMaxAge));
  const age = (Date.now() - newInShopTime) / ONE_DAY_IN_MS || 0;
  return age < MAX_AGE && age > 0;
};

export const normalizeProducts = (products: Hit[]): ProductTileInfo[] => {
  return products.map((product: Hit): ProductTileInfo => {
    const variant = product.variantValues[0] || {};
    const lowestPriceVariant = getLowestPriceVariant(product.variantValues);
    const master = product.masterValues || {};
    const baseProductNo = master.masterProductNumber;
    const { brand, brandNameShort, productLineName } = master;

    return {
      baseProductNo,
      name: master.name,
      trackingName: master.longName || master.name,
      imageUri: variant.imageURL,
      brandName: productLineName || brandNameShort || brand,
      freeShipping: master.freeShipping,
      categoryPath: master.categoryPath && master.categoryPath.length ? master.categoryPath[0] : [],
      isNewInShop: isProductNew(master.newInShop),
      price: {
        isStartingPrice: determineIfProductHasStartingPrice(variant, product.variantValues),
        countryCode: 'DE',
        currencyCode: 'EUR',
        value: lowestPriceVariant.price,
        label: lowestPriceVariant.priceLabel,
        reference: lowestPriceVariant?.referencePrice
          ? {
              label: lowestPriceVariant.referencePriceLabel,
              saving: lowestPriceVariant.referencePriceSaving,
              savingPercent: lowestPriceVariant.referencePriceSavingPercent,
              type: lowestPriceVariant.referencePriceType,
              value: lowestPriceVariant.referencePrice,
            }
          : undefined,
        base: lowestPriceVariant?.basePrice
          ? {
              amount: lowestPriceVariant.basePriceAmount,
              unit: lowestPriceVariant.basePriceUnit,
              value: lowestPriceVariant.basePrice,
            }
          : undefined,
      },
    };
  });
};

interface ReferencePrice {
  saving: number;
  savingPercent: number;
  value: number;
}

interface BasePrice {
  amount: number | null;
  unit: string | null;
  value: number | null;
}

interface CurrentPrice {
  value: number;
  reference?: ReferencePrice;
  base?: BasePrice;
}

interface Variant {
  sku: string;
  currentPrice: CurrentPrice;
  imageUri?: string;
  colorFamilies?: string[];
  colors?: string[];
  alloys?: string[];
  priceLabels?: string[];
  sizes?: string[];
  inStock?: boolean;
}

function getLowestPriceVariantForSearchResponse(variants: Variant[]): Variant {
  return variants.reduce((lowest, current) => {
    if (!current.currentPrice || !lowest.currentPrice) {
      return lowest;
    }
    return current.currentPrice.value < lowest.currentPrice.value ? current : lowest;
  });
}

export const determineIfProductHasStartingPriceForSearchResponse = (
  variant: Variant,
  values: Variant[]
): boolean => {
  if (!variant.currentPrice) {
    return false;
  }

  return values.some(v => {
    if (!v.currentPrice) {
      return false;
    }
    return variant.currentPrice.value !== v.currentPrice.value;
  });
};

// eslint-disable-next-line
export const mapSearchResponseToProductTileInfos = (response: any): ProductTileInfo[] => {
  // eslint-disable-next-line
  return response.hits.map((hit: any) => {
    const brandName = hit.brand?.longName || hit.brand?.shortName || '';
    const productName = hit.productName?.longName || hit.productName?.shortName || '';

    const variants = hit.variants || [hit.variant];
    const primaryVariant = variants[0];
    const imageUri = primaryVariant?.image?.uri || hit.images?.[0]?.uri || '';

    const isNewInShop = isProductNew(hit.inShopSince);
    const lowestPriceVariant = getLowestPriceVariantForSearchResponse(variants);
    const isStartingPrice = determineIfProductHasStartingPriceForSearchResponse(
      lowestPriceVariant,
      variants
    );
    const inStock = primaryVariant.inStock ?? true;
    return {
      baseProductNo: hit.baseProductNo,
      name: productName,
      trackingName: productName,
      imageUri: imageUri,
      brandName: brandName,
      freeShipping: hit.freeShipping || false,
      categoryPath: hit.categoryPath ? hit.categoryPath.split('/') : [],
      isNewInShop: isNewInShop,
      price: {
        isStartingPrice: isStartingPrice,
        countryCode: 'DE',
        currencyCode: 'EUR',
        value: lowestPriceVariant.currentPrice?.value || 0,
        label: lowestPriceVariant.currentPrice?.reference,
        reference: lowestPriceVariant.currentPrice?.reference
          ? {
              label: '',
              saving: lowestPriceVariant.currentPrice.reference.saving,
              savingPercent: lowestPriceVariant.currentPrice.reference.savingPercent,
              type: '',
              value: lowestPriceVariant.currentPrice.reference.value,
            }
          : undefined,
        base: lowestPriceVariant.currentPrice?.base
          ? {
              amount: lowestPriceVariant.currentPrice.base.amount,
              unit: lowestPriceVariant.currentPrice.base.unit,
              value: lowestPriceVariant.currentPrice.base.value,
            }
          : undefined,
      },
      // a fallBack for Hello audience Case, waiting for hello to be removed
      outOfStock: hit.outOfStock != undefined ? hit.outOfStock : !inStock,
    };
  });
};
