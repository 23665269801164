import { Box } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import { ProductPreview } from '../../../shows/model/shows';
import { sendMessageToNativeApp } from '../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../storage/nativeMessages';
import ImagePreview from '../ImagePreview/ImagePreview';
import styles from './ProductSlider.module.scss';
import VoucherLabel from './VoucherLabel';

interface Props {
  className?: string;
  productsPreview: ProductPreview[];
  isLarge?: boolean;
  forAdmin?: boolean;
}

const ProductSlider = ({ className, productsPreview, isLarge, forAdmin = false }: Props) => {
  const imageClickedHandler = (productId: string) => {
    sendMessageToNativeApp(buildClickMessageEvent(ClickEventElement.DISPLAY_PDP, productId));
  };

  return (
    <div className={className} data-testid="product-slider">
      <div className={styles.product_slider}>
        {productsPreview.map(productPreview => {
          return (
            <Box
              key={productPreview.baseProductNo}
              className={cx(styles.product_tile, { [styles.large]: isLarge })}
              onClick={() => {
                imageClickedHandler(productPreview.baseProductNo);
              }}
              data-testid="product-preview"
            >
              <ImagePreview
                imageUrl={productPreview.thumbnail}
                alt={productPreview.thumbnail ?? `${productPreview.baseProductNo} alt`}
                size={320}
              />
              <div
                className={cx(styles.image_overlay, {
                  [styles.dark_layer]: productPreview.soldOut,
                })}
              />
              <VoucherLabel product={productPreview} forAdmin={forAdmin} />
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default ProductSlider;
