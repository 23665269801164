import { Button, EButtonSize, EButtonType, IconChatBox, IconTrash } from '@hse24/shared-components';
import { ChevronLeft, VisibilityOffOutlined } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Chip, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Icons from '../../../assets';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import Icon from '../../../components/Icon/Icon';
import Config from '../../../config/config';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import {
  changeShowVisibility,
  deleteShowByAdmin,
  fetchShowDetails,
  selectShowDetailsData,
  selectShowDetailsLoading,
} from '../../state/showsSlice';
import Loading from '../Loading/Loading';
import CreatorInfo from './CreatorInfo/CreatorInfo';
import DeletionConfirmationDialog from './DeletionConfirmationDialog/DeletionConfirmationDialog';
import ExternalDeviceInfo from './ExternalDeviceInfo/ExternalDeviceInfo';
import ShowCardWithShowDetails from './ShowCardWithShowDetails/ShowCardWithShowDetails';
import styles from './ShowDetails.module.scss';
import ShowInfo from './ShowInfo/ShowInfo';
import UnpublishedConfirmationDialog from './UnpublishedConfirmationDialog/UnpublishedConfirmationDialog';
import VoucherDetails from './VoucherDetails/VoucherDetails';

const ShowDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { showId } = useParams<{ showId: string }>();

  const showDetails = useSelector(selectShowDetailsData);
  const loading = useSelector(selectShowDetailsLoading);
  const isHseEmployee = useSelector(selectUserIsHSEEmployee);

  const isInApp = featureFlagValue(AvailableFlag.isInApp);

  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = useState(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchShowDetails(showId));
  }, []);

  const handlePublish = (publish: boolean) => {
    dispatch(changeShowVisibility({ showId: showId, isPublished: publish }));
    handleCloseUnpublishDialog();
  };

  const handleDelete = () => {
    dispatch(deleteShowByAdmin({ showId, redirect: true }));
    handleCloseDeleteDialog();
  };

  const handleCloseUnpublishDialog = () => setIsUnpublishDialogOpen(false);

  const handleCloseDeleteDialog = () => setIsDeletionDialogOpen(false);

  const handleOpenChat = () => {
    isInApp
      ? history.push(routePaths.creator.streamChat.replace(':streamId', showId))
      : window.open(
          Config.env.baseRoute.concat(routePaths.creator.streamChat.replace(':streamId', showId)),
          '_blank'
        );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <UnpublishedConfirmationDialog
        handleClose={handleCloseUnpublishDialog}
        openDialog={isUnpublishDialogOpen}
        handleUnpublish={() => handlePublish(false)}
      />
      <DeletionConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        openDialog={isDeletionDialogOpen}
        handleDelete={handleDelete}
      />
      {showDetails && (
        <>
          <div className={styles.link_back}>
            <Button type={EButtonType.NONE} onClick={() => history.goBack()}>
              <ChevronLeft />
              Zurück
            </Button>
          </div>
          <div className={styles.show_details_container}>
            {!showDetails.isPublished && (
              <Chip
                className={styles.show_unpublished_chip}
                variant={'filled'}
                size={'small'}
                label={'unpublished'}
                icon={<VisibilityOffOutlined />}
              />
            )}
            <h1 className={styles.header}>{showDetails.title}</h1>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Grid item xs={12}>
                  <ShowCardWithShowDetails showDetails={showDetails} />
                </Grid>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <ShowInfo showDetails={showDetails} />
                  </Grid>

                  {isHseEmployee && (
                    <>
                      <Grid item xs={12}>
                        <VoucherDetails
                          isVoucherFormDisabled={!!showDetails.endedAt}
                          showId={showDetails.id}
                          voucher={
                            showDetails.vouchers?.length ? showDetails.vouchers[0] : undefined
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <CreatorInfo creatorDetails={showDetails.creatorDetails} />
                      </Grid>

                      {!showDetails.endedAt && (
                        <Grid item xs={12}>
                          <ExternalDeviceInfo showDetails={showDetails} />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Button
                          size={EButtonSize.SMALL}
                          className={styles.auto_width}
                          icon={{
                            right: <IconChatBox width="24px" height="24px" />,
                          }}
                          onClick={handleOpenChat}
                        >
                          <Typography fontWeight={700}>Öffne externen Chat</Typography>
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        {showDetails.isPublished ? (
                          <Button
                            size={EButtonSize.SMALL}
                            className={styles.auto_width}
                            type={EButtonType.BLANK}
                            icon={{ right: <Icon icon={Icons.eyesViewOffInvisibleIcon} /> }}
                            data-testid="unpublish-show-button"
                            onClick={() => setIsUnpublishDialogOpen(true)}
                          >
                            Stream verbergen
                          </Button>
                        ) : (
                          <Button
                            size={EButtonSize.SMALL}
                            className={styles.auto_width}
                            type={EButtonType.BLANK}
                            icon={{ right: <VisibilityOutlinedIcon /> }}
                            data-testid="publish-show-button"
                            onClick={() => handlePublish(true)}
                          >
                            Erneut veröffentlichen
                          </Button>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {!showDetails.startedAt && (
                          <Button
                            size={EButtonSize.SMALL}
                            className={styles.auto_width}
                            type={EButtonType.BLANK}
                            icon={{ right: <IconTrash width="24px" height="24px" /> }}
                            data-testid="delete-show-button"
                            onClick={() => setIsDeletionDialogOpen(true)}
                          >
                            {t.creators.show['Delete Stream']}
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default ShowDetails;
