import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import StyledMenu from '../../../components/StyledMenu/StyledMenu';
import StyledMenuList from '../../../components/StyledMenu/StyledMenuList';
import t from '../../../constants/translation';
import styles from '../../../posts/components/PostMenuAction/PostsMenuOptions.module.scss';
import routePaths from '../../../routes/routePaths';
import { deleteShowByAdmin, selectShowDetailsLoading } from '../../state/showsSlice';

interface Props {
  showId: string;
}

const PlannedShowsMenuOptions = ({ showId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isShowDetailsLoading = useSelector(selectShowDetailsLoading);
  const [dropDownAnchor, setDropDownAnchor] = useState<null | HTMLElement>(null);
  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] = useState(false);
  const readyToOpen = Boolean(dropDownAnchor);
  const isMobile = isMobileBreakPoint();
  const [buttonsOnMobile, setButtonsOnMobile] = useState(false);

  useEffect(() => {
    if (!isShowDetailsLoading) handleOnClose();
  }, [isShowDetailsLoading]);

  const navigateToShowDetails = () => {
    const path = generatePath(routePaths.hseEmployee.showDetails, { showId });
    history.push(path);
  };

  const handleInitialClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDropDownAnchor(event.currentTarget);
  };

  const handleOnClose = () => {
    setDropDownAnchor(null);
    setShowDeletionConfirmationDialog(false);
    setButtonsOnMobile(false);
  };

  const handleOnDelete = () => {
    setShowDeletionConfirmationDialog(true);
    setButtonsOnMobile(true);
  };

  const handleDelete = () => {
    dispatch(deleteShowByAdmin({ showId }));
    setButtonsOnMobile(false);
  };

  const MenuOptions = () => (
    <>
      <MenuItem data-testid="edit-button" onClick={navigateToShowDetails}>
        <EditIcon className={styles.item_icon} fontSize={'small'} />
        {t.creators.post.Edit}
      </MenuItem>
      <MenuItem onClick={handleOnDelete} disableRipple>
        <Box color="#BE0A14">
          <Delete color={'inherit'} />
          {t.creators.show.Delete}
        </Box>
      </MenuItem>
    </>
  );

  const renderDialogBody = () => {
    if (showDeletionConfirmationDialog) {
      return t.admins.upcomingShowsOverview['Do you really want to delete this stream?'];
    }
    return (
      isMobile && (
        <StyledMenuList>
          <MenuOptions />
        </StyledMenuList>
      )
    );
  };

  return (
    <>
      <IconButton onClick={handleInitialClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        open={!isMobile && readyToOpen && !showDeletionConfirmationDialog}
        anchorEl={dropDownAnchor}
        data-testid={'styled-menu'}
        onClose={handleOnClose}
      >
        <MenuOptions />
      </StyledMenu>
      <StyledDialog
        close={handleOnClose}
        dialogProps={{
          open: (isMobile && readyToOpen) || (!isMobile && showDeletionConfirmationDialog),
          maxWidth: isMobile ? 'md' : 'xs',
        }}
        title={t.common['Confirm deletion']}
        body={renderDialogBody()}
        cancel={
          !(isMobile && readyToOpen) || buttonsOnMobile
            ? {
                title: 'Abbrechen',
                action: handleOnClose,
              }
            : undefined
        }
        submit={
          !(isMobile && readyToOpen) || buttonsOnMobile
            ? {
                title: t.common['Permanently delete'],
                loading: isShowDetailsLoading,
                action: handleDelete,
                buttonProps: {
                  color: 'primary',
                },
              }
            : undefined
        }
      />
    </>
  );
};

export default PlannedShowsMenuOptions;
