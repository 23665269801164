import React from 'react';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import CreatePostHeader from '../../components/CreatePostHeader/CreatePostHeader';

const AdminCreatePostView = () => {
  return (
    <>
      <CreatePostHeader title="Admin Create Post Stub page" />
    </>
  );
};

function AdminCreatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.white}
      pageToRender={<AdminCreatePostView />}
      fullHeight
    />
  );
}

export default AdminCreatePostPage;
