import { Button, EButtonSize, EButtonType, IconTrash } from '@hse24/shared-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import ProductSlider from '../../../products/components/ProductSlider/ProductSlider';
import routePaths from '../../../routes/routePaths';
import {
  formatDate,
  formatTime,
  getDayOfTheWeek,
} from '../../../utils/formatters/dateFormatter/dateFormatter';
import { UpcomingShowWithCreator } from '../../api/showsRequestResponse';
import { changeShowVisibility, deleteShowByAdmin } from '../../state/showsSlice';
import DeletionConfirmationDialog from '../ShowDetails/DeletionConfirmationDialog/DeletionConfirmationDialog';
import UnpublishedConfirmationDialog from '../ShowDetails/UnpublishedConfirmationDialog/UnpublishedConfirmationDialog';
import styles from './AdminPlannedShowTile.module.scss';
import ShowMenuOptions from './ShowMenuOptions/ShowMenuOptions';

interface AdminPlannedShowTileProps {
  show: UpcomingShowWithCreator;
}

const AdminPlannedShowTile = ({ show }: AdminPlannedShowTileProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState<boolean>(false);
  const [unpublishModelOpen, setUnpublishModelOpen] = useState<boolean>(false);

  const navigateToShowDetails = () => {
    const path = generatePath(routePaths.hseEmployee.showDetails, { showId: show.id });
    history.push(path);
  };

  function onMenuIconClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchor(e.currentTarget);
  }

  const onMenuClose = () => {
    setAnchor(null);
  };

  const onDeleteClicked = () => {
    setAnchor(null);
    setDeleteModelOpen(true);
  };

  const onUnpublishClicked = () => {
    setAnchor(null);
    if (show.isPublished) {
      setUnpublishModelOpen(true);
    } else {
      handleUnpublish(true);
    }
  };

  const handleDelete = () => {
    dispatch(deleteShowByAdmin({ showId: show.id }));
  };

  const handleUnpublish = (isPublished = false) => {
    dispatch(
      changeShowVisibility({ showId: show.id, isPublished, updateUpcomingShowsOverview: true })
    );
  };

  const handleCloseDeleteDialog = () => {
    setDeleteModelOpen(false);
  };

  const handleCloseUnpublishDialog = () => {
    setUnpublishModelOpen(false);
  };

  return (
    <div className={styles.container} data-testid="admin-planned-show-tile">
      {!show.isPublished && <div className={styles.overlay} />}
      <DeletionConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        openDialog={deleteModelOpen}
        handleDelete={handleDelete}
      />
      <UnpublishedConfirmationDialog
        handleClose={handleCloseUnpublishDialog}
        openDialog={unpublishModelOpen}
        handleUnpublish={handleUnpublish}
      />
      <ShowMenuOptions
        show={show}
        anchorEl={anchor}
        isOpen={!!anchor}
        handleClose={onMenuClose}
        handleDelete={onDeleteClicked}
        handleChangeVisibility={onUnpublishClicked}
        testId={'show-menu-options'}
      />
      <div className={styles.images_container}>
        <img
          src={`${show.previewImageUrl}?imwidth=160`}
          alt={show.title}
          className={styles.show_image}
          data-testid="streamer-preview-image"
        />
        <div className={styles.streamer_image_overlay} />
        <div className={styles.streamer_info}>
          <Avatar
            src={`${show.creator.profileImageUrl}`}
            alt={show.creator.name}
            className={styles.streamer_avatar}
            data-testid="streamer-image-avatar"
          >
            <PersonIcon fontSize="small" />
          </Avatar>
          <span className={styles.streamer_name} data-testid={'streamer-name'}>
            {show.creator.name}
          </span>
        </div>
      </div>
      <div className={styles.show_details}>
        <div className={styles.date_and_action_buttons}>
          <div className={styles.date} data-testid="stream-date">
            <span>{getDayOfTheWeek(show.scheduledAt)}</span>&nbsp;|&nbsp;
            <span>{formatDate(show.scheduledAt)}</span>&nbsp;|&nbsp;
            <span>{formatTime(show.scheduledAt)}</span>
          </div>
          <div className={styles.show_icon_buttons}>
            <IconButton
              className={styles.icon_button}
              onClick={navigateToShowDetails}
              data-testid="show-icon-button"
            >
              <Icon icon={Icons.pencilIcon} style={{ color: 'black' }} dataTestId="pencil-icon" />
            </IconButton>
            <IconButton
              className={styles.delete_icon_button}
              onClick={onDeleteClicked}
              data-testid="show-icon-button"
            >
              <IconTrash />
            </IconButton>
            <IconButton
              className={styles.icon_button}
              onClick={onMenuIconClick}
              data-testid="show-icon-button"
            >
              <Icon icon={Icons.moreIcon} style={{ color: 'black' }} dataTestId="more-icon" />
            </IconButton>
          </div>
        </div>
        <span className={styles.sales_topic}>{show.salesTopic}</span>
        <span className={styles.title}>{show.title}</span>
        <div className={styles.products_wrapper}>
          <ProductSlider
            className={styles.product_slider}
            productsPreview={show.products}
            forAdmin={true}
          />
          <div className={styles.show_info_button_wrapper} onClick={navigateToShowDetails}>
            <Button
              type={EButtonType.NONE}
              className={styles.show_info_button}
              size={EButtonSize.SMALL}
            >
              {t.admins.upcomingShowsOverview['View stream details']}
            </Button>
            <ArrowForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPlannedShowTile;
