import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import React from 'react';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import { ProductTileInfo } from '../../../model/productTileInfo';
import ImagePreview from '../../ImagePreview/ImagePreview';
import t from './../../../../constants/translation';
import styles from './ProductSelectionItem.module.scss';

interface Props {
  direction: 'row' | 'column';
  onRemoveHandler?: () => void;
  product: ProductTileInfo;
}

function ProductSelectionItem({ product, onRemoveHandler, direction }: Props) {
  const isColumn = direction === 'column';
  const isDisabled = product.outOfStock;
  const opacity = isDisabled ? 0.5 : 1;
  return (
    <Box
      className={styles.product_item}
      display="flex"
      flexDirection={direction}
      alignItems="center"
    >
      <Box position="relative">
        <Box className={styles.image} sx={{ width: '6.625rem', opacity }}>
          {onRemoveHandler && (
            <Box className={styles.remove}>
              <IconButton onClick={onRemoveHandler}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
          <ImagePreview alt={product.name} imageUrl={product.imageUri} size={320} />
        </Box>
        {isDisabled && (
          <Chip
            label={
              <Typography fontSize={10} fontWeight={500} color="white">
                {t.common['Sold out']}
              </Typography>
            }
            className={styles.chip}
          />
        )}
      </Box>
      <Box
        sx={
          isColumn
            ? { width: '6.625rem', marginLeft: 0, opacity }
            : { marginLeft: 1, width: 'object-fit', opacity }
        }
      >
        <Typography className={styles.substring} variant="caption">
          {product.brandName}
        </Typography>
        <Typography className={styles.product_name} variant="body2">
          {product.name}
        </Typography>
        <Box>
          <PriceInfo price={product.price} isColumn={isColumn} />
        </Box>
      </Box>
    </Box>
  );
}

export default ProductSelectionItem;
