import { useParams } from 'react-router-dom';
import Details from '../../../layout/Details/Details';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import CreatorDetailsPerformance from '../../components/CreatorDetailsPerformance/CreatorDetailsPerformance';
import CreatorDetailsProfile from './CreatorDetailsProfile/CreatorDetailsProfile';

/**
 * Details page of a creator.
 * */
const CreatorDetailsPage = () => {
  const component = () => <StreamerDetailsInternal />;
  return <PageContainer backgroundColor={theme.whiteLilac} pageToRender={component()} />;
};

const StreamerDetailsInternal = () => {
  const { streamerId } = useParams<{ streamerId: string }>();
  return (
    <Details
      overview={<CreatorDetailsProfile />}
      performance={<CreatorDetailsPerformance creatorId={streamerId} />}
    />
  );
};

export default CreatorDetailsPage;
