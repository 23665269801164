import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Fab, Typography } from '@mui/material';
import cx from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import CropModal from '../../../components/CropModal/CropModal';
import t from '../../../constants/translation';
import useValidateAndCropImage from '../../../shared/images/useValidateAndCropImage';
import { Aspect, CropSource } from '../../../utils/images/cropImageFns';
import { MediaType } from '../../api/postsRequestResponse';
import { PostImageMetadata } from '../../model/post';
import { extractMediaType } from '../../utils/extractMediaType';
import styles from '../CreateEditPostForm/CreateEditPostForm.module.scss';

interface Props {
  croppedPostImage: PostImageMetadata | null;
  setCroppedPostImage: (postImage: PostImageMetadata | null) => void;
  disabled?: boolean;
}

function CreatePostImage({ croppedPostImage, setCroppedPostImage, disabled = false }: Props) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [postImage, setPostImage] = useState<string | null>(null);
  const validateAndCrop = useValidateAndCropImage();

  const handleInputImageClick = () => {
    if (hiddenFileInput.current && !disabled) {
      hiddenFileInput.current.click();
    }
  };

  const handleInputImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: string | null = await validateAndCrop(event, false, true);

    if (file) {
      const postFile = extractMediaType(file);

      if (postFile?.type === 'video') {
        setCroppedPostImage({
          imageUrl: file,
          fileExtension: postFile.extension,
          mediaType: MediaType.VIDEO,
        });
        setPostImage(null);
      } else {
        setPostImage(file);
      }
    }
  };

  const handleRemoveImage = () => {
    setCroppedPostImage(null);
    setPostImage(null);
  };

  const onPostImageSave = (image: Blob) => {
    const imageURL = URL.createObjectURL(image);
    setCroppedPostImage({
      imageUrl: imageURL,
      fileExtension: 'jpeg',
    });
    setPostImage(null);
  };
  return (
    <>
      <Box
        className={cx(styles.image_zone, { [styles.disabled]: disabled })}
        onClick={handleInputImageClick}
      >
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'} gap={1}>
          <AddPhotoAlternateOutlinedIcon color={'disabled'} fontSize={'large'} />
          <Typography variant="body1">{t.creators.post['Upload image']}</Typography>
        </Box>
        {croppedPostImage && (
          <Box className={styles.image_box}>
            <img src={croppedPostImage.imageUrl} alt={'Post image'} data-testid={'post-image'} />
          </Box>
        )}
        <Box className={cx(styles.buttons, { [styles.hide]: disabled })}>
          <input
            className={styles.input}
            type="file"
            onChange={event => handleInputImageChange(event)}
            ref={hiddenFileInput}
            onClick={event => {
              const element = event.target as HTMLInputElement;
              element.value = '';
            }}
            data-testid={'post-image-input'}
          />
          {croppedPostImage && (
            <>
              <span color={'white'}>
                <Fab color="inherit" size={'large'} onClick={handleRemoveImage}>
                  <DeleteOutlineRoundedIcon color={'error'} fontSize={'medium'} />
                </Fab>
              </span>
              <Fab color="primary" size={'large'}>
                <EditIcon />
              </Fab>
            </>
          )}
        </Box>
      </Box>
      {postImage && (
        <CropModal
          imageUrl={postImage}
          onSaveHandler={onPostImageSave}
          handleCancelCrop={() => setPostImage(null)}
          cropSource={CropSource.POST}
          aspect={Aspect.RATIO_1_1}
          title={'Vorschaubild hochladen'}
          description={'Dieses Bild ist öffentlich sichtbar.'}
        />
      )}
    </>
  );
}

export default CreatePostImage;
