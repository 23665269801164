import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectUserIsHSEEmployee } from '../../auth/state/authSlice';
import { QueryParams } from '../../common/types';
import { addPathQuery } from '../../common/utils';
import { selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import { errorOccurred, showSnackbar } from '../../notifications/state/notificationsSlice';
import { ProductTileInfo } from '../../products/model/productTileInfo';
import {
  addToProductsSelectionSelectedProducts,
  clearProductsSelection,
} from '../../products/state/productsSlice';
import routePaths from '../../routes/routePaths';
import { put as sendPutRequest } from '../../shared/axiosClient';
import showsApi from '../../shows/api/showsApi';
import getBlobFromUrl from '../../utils/images/getBlobFromUrl';
import { parseError } from '../../utils/parseError/parseError';
import postsApi from '../api/postsApi';
import {
  PostCreationRequest,
  PostPreSignedUrlRequest,
  PostPreSignedUrlResponse,
  PostStatusRequestResponse,
  PostUpdatePayload,
  StreamerPostResponse,
  StreamerPostsResponse,
} from '../api/postsRequestResponse';
import { PostData, PostStatus } from '../model/post';
import { extractPostIdFromPreSignedUrl } from '../utils/extractPostIdFromPreSignedUrl';
import {
  changePostStatus,
  createNewPost,
  fetchAdminPostsOverview,
  fetchStreamerPost,
  fetchStreamerPosts,
  fetchStreamerPostsStats,
  PostsStatsData,
  selectStreamerPostsPageable,
  setAdminPostsOverview,
  setAdminPostStatus,
  setCreatingPostLoading,
  setPost,
  setPostStatusLoading,
  setStreamerPosts,
  setStreamerPostsLoading,
  setStreamerPostsStats,
  setStreamerPostsStatsLoading,
  setStreamerPostStatus,
  updatePost,
} from './postsSlice';

export function* createNewPostHandler(action: PayloadAction<PostData>) {
  try {
    yield put(setCreatingPostLoading(true));
    const postPreSignedUrlRequest: PostPreSignedUrlRequest = {
      fileExtension: action.payload.preview.fileExtension,
      mediaType: action.payload.preview.mediaType,
    };
    const uploadImageResponse: PostPreSignedUrlResponse = yield call(
      uploadPostImage,
      action.payload.preview.imageUrl,
      postPreSignedUrlRequest
    );

    const postId = extractPostIdFromPreSignedUrl(uploadImageResponse.key);

    if (postId == null) {
      throw new Error('Pre signed URL format invalid');
    }

    const postCreationRequest: PostCreationRequest = {
      postId,
      fileExtension: action.payload.preview.fileExtension,
      baseProductsNo: action.payload.baseProductsNo,
      caption: action.payload.caption,
      s3Key: uploadImageResponse.key,
      mediaType: action.payload.preview.mediaType,
      scheduleAt: action.payload.scheduleAt,
    };
    yield call(postsApi.createNewPost, postCreationRequest);
    yield put(push(routePaths.creator.dashboard));
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}

export function* uploadPostImage(imageUrl: string, request: PostPreSignedUrlRequest) {
  const preSignedUrlResponse: PostPreSignedUrlResponse = yield call(
    postsApi.generatePreSignedUrlForPost,
    request
  );
  const previewImageData: Blob = yield call(getBlobFromUrl, imageUrl);
  yield call(sendPutRequest, preSignedUrlResponse.preSignedUrl, previewImageData);

  return preSignedUrlResponse;
}

export function* fetchStreamerPostsHandler() {
  try {
    yield put(setStreamerPostsLoading(true));
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    const pageable: ReturnType<typeof selectStreamerPostsPageable> = yield select(
      selectStreamerPostsPageable
    );
    if (creator) {
      const response: StreamerPostsResponse = yield call(
        postsApi.fetchStreamerPosts,
        creator.id,
        pageable
      );
      yield put(setStreamerPosts(response));
    }
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setStreamerPostsLoading(false));
  }
}

export function* changePostStatusHandler(action: PayloadAction<PostStatusRequestResponse>) {
  const { postId, postStatus } = action.payload;
  try {
    yield put(setPostStatusLoading(true));
    let response: PostStatusRequestResponse;
    switch (postStatus) {
      case PostStatus.PUBLISHED:
        response = yield call(postsApi.republishPost, postId);
        break;
      default:
        response = yield call(postsApi.unpublishPost, postId);
        break;
    }
    const isAdmin: boolean = yield select(selectUserIsHSEEmployee);
    if (isAdmin) {
      yield put(setAdminPostStatus(response));
    } else {
      yield put(setStreamerPostStatus(response));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setPostStatusLoading(false));
  }
}

export function* fetchStreamerPostsStatsHandler() {
  try {
    yield put(setStreamerPostsStatsLoading(true));
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    if (creator) {
      const response: PostsStatsData = yield call(postsApi.fetchStreamerPostsStats, creator.id);
      yield put(setStreamerPostsStats(response));
    }
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setStreamerPostsStatsLoading(false));
  }
}

export function* fetchAdminPostsOverviewHandler(action: PayloadAction<QueryParams>) {
  try {
    yield put(setAdminPostsOverview({ loading: true }));
    const postsResponse: StreamerPostsResponse = yield call(
      postsApi.fetchAdminPostsOverview,
      action.payload
    );
    yield put(setAdminPostsOverview({ ...postsResponse, queryParams: action.payload }));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setAdminPostsOverview({ loading: false }));
  }
}

export function* fetchStreamerPostHandler(action: PayloadAction<string>) {
  try {
    const postId = action.payload;
    const post: StreamerPostResponse = yield call(postsApi.getStreamerPostById, postId);
    const baseProductNos = post.products?.map(p => p.baseProductNo);
    if (baseProductNos?.length) {
      const products: ProductTileInfo[] = yield call(
        showsApi.getProductsDetailsByBaseProductNos,
        baseProductNos
      );
      yield put(addToProductsSelectionSelectedProducts(products));
    } else {
      yield put(addToProductsSelectionSelectedProducts([]));
    }
    yield put(setPost(post));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* updatePostHandler(action: PayloadAction<PostUpdatePayload>) {
  try {
    const isAdmin: boolean = yield select(selectUserIsHSEEmployee);

    yield put(setCreatingPostLoading(true));

    const { postId, request } = action.payload;
    yield call(postsApi.updatePost, postId, request);
    yield put(showSnackbar({ text: 'Post erfolgreich geändert 🎉' }));
    yield put(clearProductsSelection());
    const path = isAdmin
      ? routePaths.hseEmployee.postsOverview
      : addPathQuery(routePaths.creator.analytics, {
          tab: 'Posts',
        });
    yield put(push(path));
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: `Error occurred when trying to update post ${action.payload.postId}`,
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}

export function* watcherPostsSagas() {
  yield takeLatest(createNewPost.type, createNewPostHandler);
  yield takeLatest(fetchStreamerPosts.type, fetchStreamerPostsHandler);
  yield takeLatest(fetchStreamerPostsStats.type, fetchStreamerPostsStatsHandler);
  yield takeLatest(changePostStatus.type, changePostStatusHandler);
  yield takeLatest(fetchAdminPostsOverview.type, fetchAdminPostsOverviewHandler);
  yield takeLatest(fetchAdminPostsOverview.type, fetchAdminPostsOverviewHandler);
  yield takeLatest(fetchStreamerPost.type, fetchStreamerPostHandler);
  yield takeLatest(updatePost.type, updatePostHandler);
}
