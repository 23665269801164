import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Audience } from '../../../common/types';
import AudienceFilter from '../../../components/AudienceFilter/AudienceFilter';
import DateFilterInFuture, {
  FutureDateFilters,
} from '../../../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import t from '../../../constants/translation';
import { Pageable } from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import PlannedShowsTable from '../../component/PlannedShowsTable/PlannedShowsTable';
import { StreamedBy } from '../../model/shows';
import {
  QueryData,
  selectAdminUpcomingShowsQueryData,
  setAdminUpcomingShowsQueryData,
} from '../../state/showsSlice';
import styles from './UpcomingOverviewPage.module.scss';

const mapQueryDataToPageable = (queryData: QueryData<FutureDateFilters>): Pageable => ({
  page: queryData.page,
  pageSize: queryData.pageSize,
  sortField: queryData.sortField,
  sortOrder: queryData.sortOrder,
});

const UpcomingShowsOverviewPage = () => {
  const AdminUpcomingShowsOverview = () => {
    const queryData = useSelector(selectAdminUpcomingShowsQueryData);

    const dispatch = useDispatch();

    const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          dateFrom: dateRangeWithSelectedFilter.startDate,
          dateTo: dateRangeWithSelectedFilter.endDate,
          dateFilter: dateRangeWithSelectedFilter.dateFilter as FutureDateFilters,
        })
      );

    const onPageableChange = (pageable: Pageable) =>
      dispatch(setAdminUpcomingShowsQueryData({ ...pageable }));

    const resetSearchTermOnInit = () => {
      // We have to reset the searchTerm (which is used in the search field) on init,
      // because it's not possible to set its value and show in the search field (which belongs to the material ui data grid).
      // If we don't do that, there will be filtered out results (which contain the search term) but the search field will be empty, what leads to confusion.
      dispatch(setAdminUpcomingShowsQueryData({ searchTerm: undefined }));
    };

    useEffect(() => {
      resetSearchTermOnInit();
    }, [dispatch]);

    const onFilterAudience = (audience: Audience[]) =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          audience,
        })
      );

    const toggleExcludeEarlyBirdShowsChanged = () =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          excludeEarlyBirds: !(queryData.excludeEarlyBirds ?? false),
        })
      );

    const toggleStreamedByHSEOnly = () => {
      return dispatch(
        setAdminUpcomingShowsQueryData({
          streamedBy: (queryData.streamedBy ?? [])?.includes(StreamedBy.HSE)
            ? []
            : [StreamedBy.HSE],
        })
      );
    };

    return (
      <div className={styles.container}>
        <Box className={styles.table_filter}>
          <Box>
            <DateFilterInFuture
              onDateFiltersChange={onDateFiltersChange}
              selectedFilter={queryData.dateFilter}
            />
          </Box>
          <Box>
            <Typography variant={'h6'} mb={2}>
              Quick filter
            </Typography>
            <Box className={styles.quick_filters_wrapper}>
              <Button
                className={styles.quick_filters_button}
                size={EButtonSize.SMALL}
                type={
                  queryData.excludeEarlyBirds === true ? EButtonType.PRIMARY : EButtonType.BLANK
                }
                onClick={toggleExcludeEarlyBirdShowsChanged}
              >
                {t.creators.show.hideEarlyShopping}
              </Button>
              <Button
                className={styles.quick_filters_button}
                size={EButtonSize.SMALL}
                type={
                  queryData.streamedBy?.length == 1 && queryData.streamedBy.includes(StreamedBy.HSE)
                    ? EButtonType.PRIMARY
                    : EButtonType.BLANK
                }
                onClick={toggleStreamedByHSEOnly}
              >
                {t.creators.show.streamedByHSEOnly}
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography variant={'h6'} mb={2}>
              Audience
            </Typography>
            <AudienceFilter audienceFilter={queryData.audience} onSelect={onFilterAudience} />
          </Box>
        </Box>
        <Box>
          <PlannedShowsTable
            pageable={mapQueryDataToPageable(queryData)}
            pageableCallback={onPageableChange}
          />
        </Box>
      </div>
    );
  };
  return (
    <PageContainer backgroundColor={theme.whiteLilac} pageToRender={AdminUpcomingShowsOverview()} />
  );
};

export default UpcomingShowsOverviewPage;
