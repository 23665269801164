import LockIcon from '@mui/icons-material/Lock';
import { IconButton, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useState } from 'react';

interface DesktopProfileLockInfoTooltipProps {
  title: string;
}

const DesktopProfileLockInfoTooltip = ({ title }: DesktopProfileLockInfoTooltipProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      onClose={() => setOpen(false)}
      open={open}
      title={title}
      placement="right"
    >
      <IconButton
        data-testid="desktop-lock-icon"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <LockIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DesktopProfileLockInfoTooltip;
