import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import { StreamedBy } from '../../../model/shows';
import { updateStreamedBy } from '../../../state/showsSlice';
import StreamedByInfoDefault from './StreamedByInfoDefault';
import StreamedByInfoEditable from './StreamedByInfoEditable';

interface Props {
  showId: string;
  streamedBy: StreamedBy;
}

export interface Option {
  value: string;
  icon: React.ReactElement;
  primaryText: string;
  secondaryText: string;
}

const defaultOption: Option = {
  value: StreamedBy.CREATOR,
  icon: <Icon dataTestId="video-camera-icon" icon={Icons.starWithCircle} />,
  primaryText: 'Creator',
  secondaryText: 'Von Creator geplant und durchgeführt',
};

const options: Option[] = [
  {
    value: StreamedBy.HSE,
    icon: <Icon dataTestId="video-camera-icon" icon={Icons.videoCameraWithCircle} />,
    primaryText: 'HSE',
    secondaryText: 'Von HSE geplant und bei Umsetzung unterstützt',
  },
  defaultOption,
];

const StreamedByInfo = ({ showId, streamedBy }: Props) => {
  const dispatch = useDispatch();

  const [pageEditable, setPageEditable] = useState(false);
  const [selectedValue, setSelectedValue] = useState<StreamedBy>(streamedBy);

  const togglePageEditable = () => {
    setPageEditable(!pageEditable);
  };

  const handleRadioOptionClick = (value: string) => {
    setSelectedValue(value as StreamedBy);
  };

  const handleSubmitAfterEditing = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      updateStreamedBy({
        showId,
        streamedBy: selectedValue,
      })
    );
    togglePageEditable();
  };

  return (
    <>
      {!pageEditable && (
        <StreamedByInfoDefault
          selectedOption={options.find(option => option.value === selectedValue) || defaultOption}
          toggleEditMode={togglePageEditable}
        />
      )}
      {pageEditable && (
        <StreamedByInfoEditable
          options={options}
          selectedValue={selectedValue}
          handleClick={handleRadioOptionClick}
          handleSubmit={handleSubmitAfterEditing}
        />
      )}
    </>
  );
};

export default StreamedByInfo;
