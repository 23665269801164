import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import { clearProductsSelection } from '../../../products/state/productsSlice';
import theme from '../../../theme/theme.module.scss';
import { ShowRequest } from '../../api/showsRequestResponse';
import ScheduleShowForm from '../../component/ScheduleShowForm/ScheduleShowForm';
import { createNewShow } from '../../state/showsSlice';

const CreateShowPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProductsSelection());
  }, []);

  const onCreateShow = (values: ShowRequest) => dispatch(createNewShow(values));

  return (
    <PageContainer
      backgroundColor={theme.whiteLilac}
      pageToRender={<ScheduleShowForm isCreate submitCallback={onCreateShow} />}
    />
  );
};

export default CreateShowPage;
