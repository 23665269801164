import AgencyStreamersOverviewPage from '../agencies/pages/AgencyStreamersOverviewPage/AgencyStreamersOverviewPage';
import { Role } from '../auth/role';
import CreatorDetailsPage from '../creators/pages/CreatorDetailsPage/CreatorDetailsPage';
import PastShowsOverviewPage from '../shows/pages/PastShowsOverviewPage/PastShowsOverviewPage';
import UpcomingShowsOverviewPage from '../shows/pages/UpcomingShowsOverviewPage/UpcomingShowsOverviewPage';
import { RouteDetails } from './routeDetails';
import routePaths from './routePaths';

export const agencyRoutes: Record<string, RouteDetails> = {
  streamersOverview: {
    path: routePaths.agency.creatorsOverview,
    component: AgencyStreamersOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.AGENCY],
    hideDesktopFooter: true,
  },
  streamersPastShows: {
    path: routePaths.agency.pastShowsOverview,
    component: PastShowsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.AGENCY],
    hideDesktopFooter: true,
  },
  streamerDetailsAgency: {
    path: routePaths.agency.creatorDetails,
    component: CreatorDetailsPage,
    exact: true,
    protected: true,
    roles: [Role.AGENCY],
    hideDesktopFooter: true,
  },
  upcomingShowsAgency: {
    path: routePaths.agency.upcomingShows,
    component: UpcomingShowsOverviewPage,
    exact: true,
    protected: true,
    roles: [Role.AGENCY],
    hideDesktopFooter: true,
  },
};
