import { Button, EButtonSize, EButtonType, IconWithText } from '@hse24/shared-components';
import { Box, ListItem, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { Option } from './StreamedByInfo';
import styles from './StreamedByInfo.module.scss';

interface Props {
  selectedOption: Option;
  toggleEditMode: () => void;
}

const StreamedByInfoDefault = ({ selectedOption, toggleEditMode }: Props) => {
  return (
    <Box
      data-testid={'streamed-by-container'}
      className={styles.streamed_by_container}
      sx={{ my: 3 }}
    >
      <Box data-testid={'content'}>
        <Box className={styles.default_page_header}>
          <Typography fontWeight={'600'} mt={'3px'} sx={{ fontSize: '16px' }}>
            Stream-Typ:
          </Typography>
          <Button
            onClick={toggleEditMode}
            type={EButtonType.BLANK}
            className={styles.toggle_btn}
            size={EButtonSize.SMALL}
          >
            Bearbeiten
          </Button>
        </Box>
        <List>
          <ListItem key={selectedOption.value} sx={{ pl: '4px' }}>
            <ListItemText>
              <IconWithText icon={selectedOption.icon} style={{ alignItems: 'center' }}>
                <Typography variant="body1">{selectedOption.primaryText}</Typography>
                <Typography variant="body2">{selectedOption.secondaryText}</Typography>
              </IconWithText>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default StreamedByInfoDefault;
