import { Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import SideNavigationWithContent, {
  NavItem,
} from '../SideNavigationWithContent/SideNavigationWithContent';
import TabsNavigationWithContent from '../TabsNavigationWithContent/TabsNavigationWithContent';

interface Props {
  renderContent: (selectedIndex: number) => JSX.Element;
  navItems: NavItem[];
}

const PageWithNavigation = ({ renderContent, navItems }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleIndexChange = (index: number): JSX.Element => {
    if (index != selectedIndex) {
      setSelectedIndex(index);
    }
    return renderContent(selectedIndex);
  };

  const renderTabs = () => (
    <TabsNavigationWithContent
      navItems={navItems}
      handleIndexChange={handleIndexChange}
      selectedIndex={selectedIndex}
    />
  );

  const renderSideNav = () => (
    <SideNavigationWithContent
      navItems={navItems}
      handleIndexChange={handleIndexChange}
      selectedIndex={selectedIndex}
    />
  );

  return <>{isMobile ? renderTabs() : renderSideNav()}</>;
};

export default PageWithNavigation;
