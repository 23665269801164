export type ScriptDetails = {
  srcUrl: string;
  id: string | undefined;
  attributes: Record<string, string>;
};

export const userCentricsConsentScriptDetails: ScriptDetails = {
  srcUrl: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
  id: 'usercentrics-cmp',
  attributes: { 'data-settings-id': 'ZNqJ00rHa' },
};
