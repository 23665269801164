import { createContext, ReactNode } from 'react';
import Config from './config';

export const FeatureFlagsContext = createContext({});

export enum AvailableFlag {
  isInApp = 'isInApp',
  AmasEnabled = 'amasEnabled',
  enableApplyVoucher = 'enableApplyVoucher',
  enableStreamDeleting = 'enableStreamDeleting',
  isClassicNewRatioEnabled = 'isClassicNewRatioEnabled',
  goodbyeMessageForHelloStreamer = 'goodbyeMessageForHelloStreamer',
  isNewDashboardActive = 'isNewDashboardActive',
  isAdminCreatePostActive = 'isAdminCreatePostActive',
}

type Children = JSX.Element[] | JSX.Element | null | string;

export const FeatureFlags = (props: { children: Children; values?: Record<string, boolean> }) => (
  <FeatureFlagsContext.Provider value={Config.env.flags}>
    {props.children}
  </FeatureFlagsContext.Provider>
);

interface Prop {
  flag: AvailableFlag;
  showOnDisabled?: boolean;
  children: Children;
}

export const Flag = ({ flag, showOnDisabled, children }: Prop) => (
  <FeatureFlagsContext.Consumer>
    {(flagsObj: Record<string, boolean>) => {
      if (showOnDisabled) {
        return flagsObj[flag] ? null : children;
      }
      return flagsObj[flag] ? children : null;
    }}
  </FeatureFlagsContext.Consumer>
);

interface FeatureFlagProps {
  flag: AvailableFlag;
  render: (activated: boolean) => ReactNode;
}

export const FeatureFlag = ({ flag, render }: FeatureFlagProps) => (
  <FeatureFlagsContext.Consumer>
    {(flagsObj: Record<string, boolean>) => render(flagsObj[flag])}
  </FeatureFlagsContext.Consumer>
);
