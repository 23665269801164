import React from 'react';
import t from '../../../constants/translation';
import { ProductPreview } from '../../../shows/model/shows';
import { VoucherType } from '../../../shows/model/voucher';
import { getVoucherLabel } from '../../../utils/formatters/voucherFormatter/voucherFormatter';
import styles from './ProductSlider.module.scss';

// https://www.figma.com/design/BlreMsa3KzZoPfm9JqJae3/UXD-141%3A-admin-stream-creation-%26-overview?node-id=3806-375200&t=XPaWM1CsQdU3qYEg-4
const VoucherLabel = ({ product, forAdmin }: { product: ProductPreview; forAdmin: boolean }) => {
  if (product.soldOut) {
    return (
      <span
        className={styles.sold_out_label}
        dangerouslySetInnerHTML={{ __html: t.common.soldOut }}
      />
    );
  } else if (product.voucher) {
    return (
      <span
        className={styles.voucher_label}
        dangerouslySetInnerHTML={{
          __html: forAdmin ? t.common.Voucher : getVoucherLabel(product.voucher),
        }}
      />
    );
  } else if (forAdmin && product.price?.reference?.savingPercent) {
    return (
      <span
        className={styles.regular_discount_label}
        dangerouslySetInnerHTML={{
          __html: getVoucherLabel(
            {
              voucherType: VoucherType.PERCENTAGE,
              voucherValue: product.price.reference.savingPercent,
            },
            true
          ),
        }}
      />
    );
  } else {
    return null;
  }
};

export default VoucherLabel;
