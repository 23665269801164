import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openManagedDialog } from '../../../notifications/state/notificationsSlice';
import styles from './DeleteAccount.module.scss';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal';

const DeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  const openDeleteAccountModal = () =>
    dispatch(
      openManagedDialog({
        title: (
          <span className={styles.modal_title}>
            <DeleteOutlineIcon />
            Account löschen
          </span>
        ),
        component: DeleteAccountModal.name,
      })
    );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked);

  return (
    <>
      <h3 className={styles.header}>Account löschen</h3>
      <Box mt={2}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={onChange} />}
            label="Ich will meinen Account löschen"
          />
        </FormGroup>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            disabled={!isChecked}
            onClick={openDeleteAccountModal}
          >
            Weiter
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteAccount;
