import { Theme, useMediaQuery } from '@mui/material';
import DesktopTooltip from './DesktopTooltip/DesktopTooltip';
import MobileTooltip from './MobileTooltip/MobileTooltip';

interface ResponsiveTooltipProps {
  title: string;
}

const ResponsiveTooltip = ({ title }: ResponsiveTooltipProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return isMobile ? <MobileTooltip title={title} /> : <DesktopTooltip title={title} />;
};

export default ResponsiveTooltip;
