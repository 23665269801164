import { KeyType } from '../../../shared/KeyType';
import { CreatorImageType } from '../../model/creator';

export enum Salutation {
  MS = 'MS',
  MR = 'MR',
}

export function translate(salution: Salutation) {
  switch (salution) {
    case Salutation.MR:
      return 'Herr';
    case Salutation.MS:
      return 'Frau';
  }
}

export default interface ProfilePersonalData {
  name: string | undefined | null;
  description: string | undefined | null;

  salutation: Salutation;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  phoneNumber: string | undefined | null;
  email: string;
  birthdate: string;

  accountHolderName?: string;
  iban?: string;
  bic?: string;
  vatId?: string;
  isLiableToVat?: boolean;
  taxId?: string;
  profileImage: {
    imageType: CreatorImageType;
    fileExtension: string;
    imageUrl: string;
  };
  tiktok?: string;
  facebook?: string;
  snapchat?: string;
  pinterest?: string;
  instagram?: string;
  youtube?: string;
}

export type StreamerPersonalDataUi = ProfilePersonalData;

export const profilePersonalDataKeys: KeyType<ProfilePersonalData> = {
  name: 'name',
  description: 'description',

  salutation: 'salutation',
  firstName: 'firstName',
  lastName: 'lastName',
  street: 'street',
  streetNumber: 'streetNumber',
  zipCode: 'zipCode',
  city: 'city',
  phoneNumber: 'phoneNumber',
  email: 'email',
  birthdate: 'birthdate',

  accountHolderName: 'accountHolderName',
  iban: 'iban',
  bic: 'bic',
  vatId: 'vatId',
  isLiableToVat: 'isLiableToVat',
  taxId: 'taxId',
  profileImage: 'profileImage',

  instagram: 'instagram',
  tiktok: 'tiktok',
  youtube: 'youtube',
  facebook: 'facebook',
  snapchat: 'snapchat',
  pinterest: 'pinterest',
};

export type ProfilePersonalDataWithLabels = {
  [Property in keyof ProfilePersonalData]: string;
};

export const profilePersonalDataLabels: Readonly<
  ProfilePersonalDataWithLabels & {
    taxId: string;
    vatId: string;
    accountHolderName: string;
  }
> = {
  name: 'Dein Name in der HELLO App',
  description: 'Beschreibung',

  salutation: 'Anrede',
  firstName: 'Vorname',
  lastName: 'Nachname',
  street: 'Straße',
  streetNumber: 'Hausnr.',
  zipCode: 'PLZ',
  city: 'Stadt',
  phoneNumber: 'Telefonnummer',
  email: 'E-Mail',
  birthdate: 'Geburtstag',

  accountHolderName: 'Der/die Kontoinhabende',
  iban: 'IBAN',
  bic: 'BIC',
  vatId: 'USt-IdNr.',
  taxId: 'Steueridentifikationsnummer',
  profileImage: 'Profilbild',

  instagram: 'Instagram',
  tiktok: 'TikTok',
  youtube: 'Youtube',
  facebook: 'Facebook',
  snapchat: 'Snapchat',
  pinterest: 'Pinterest',
};

export const initialCreatorData: StreamerPersonalDataUi = {
  name: '',
  description: '',
  salutation: Salutation.MS,
  firstName: '',
  lastName: '',
  street: '',
  streetNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  email: '',
  birthdate: '',

  accountHolderName: '',
  iban: '',
  bic: '',
  vatId: '',
  isLiableToVat: true,
  taxId: '',
  profileImage: {
    imageType: CreatorImageType.PROFILE,
    fileExtension: '',
    imageUrl: '',
  },
  instagram: '',
  tiktok: '',
  youtube: '',
  facebook: '',
  snapchat: '',
  pinterest: '',
};
