import { Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserIsCreator } from '../../auth/state/authSlice';
import { selectCreatorHasCompletedProfile } from '../../creators/state/creatorsSlice';
import PageContainer from '../PageContainer/PageContainer';
import { NavItem } from '../SideNavigationWithContent/SideNavigationWithContent';

interface Props {
  navItems: NavItem[];
  handleIndexChange: (selectedIndex: number) => JSX.Element;
  selectedIndex: number;
}

const TabsNavigationWithContent = ({ navItems, handleIndexChange, selectedIndex }: Props) => {
  const [renderedContent, setRenderedContent] = useState(handleIndexChange(selectedIndex));

  const userIsCreator = useSelector(selectUserIsCreator);
  const streamerHasCompletedProfile = useSelector(selectCreatorHasCompletedProfile);
  const isStreamerHasUncompletedProfile = userIsCreator && !streamerHasCompletedProfile;

  const handleChange = (event: BaseSyntheticEvent, selectedIndex: number) => {
    handleIndexChange(selectedIndex);
  };

  useEffect(() => {
    setRenderedContent(handleIndexChange(selectedIndex));
  }, [selectedIndex]);

  return (
    <>
      <Tabs
        onChange={handleChange}
        value={selectedIndex}
        aria-label="tabs navigation"
        data-testid="tabs navigation"
        variant="scrollable"
        scrollButtons={false}
        allowScrollButtonsMobile
      >
        {navItems.map((item, index) => (
          <Tab label={item.text} key={index} disabled={isStreamerHasUncompletedProfile} />
        ))}
      </Tabs>
      <PageContainer pageToRender={renderedContent} />
    </>
  );
};

export default TabsNavigationWithContent;
