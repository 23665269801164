import { GridPaginationInitialState, GridSortingInitialState } from '@mui/x-data-grid';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RootState } from '../../app/store';
import {
  ActivationStep,
  selectAuthActivationProgressStep,
  selectUserIsCreator,
  selectUserIsHelloStreamer,
} from '../../auth/state/authSlice';
import { Audience, QueryParams } from '../../common/types';
import { selectOnboardingIndicatorDialogStatus } from '../../notifications/state/notificationsSlice';
import { Currency } from '../../payments/model/Payments';
import { ShowImages, ShowImagesData } from '../../shows/model/shows';
import { CreatorLightResponse } from '../api/requestResponse';
import { AdminAddCreatorDataUi } from '../components/AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import { AgencyAddCreatorDataUi } from '../components/AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';
import ProfilePersonalData from '../components/Profile/ProfilePersonalData';
import { AddressRecommendation } from '../model/addressRecommendation';
import {
  CreatorDetailsOverview,
  CreatorPerformanceOverview,
  CreatorRole,
  CreatorsOverview,
  CreatorStatistics,
  LoggedInCreator,
} from '../model/creator';

export interface TableFilter {
  pagination?: GridPaginationInitialState;
  sorting?: GridSortingInitialState;
}

export interface CreatorsState {
  loggedInCreator: LoggedInCreator | undefined;
  acceptTermsAndConditionsInProgress: boolean;
  selectedCreator: CreatorDetailsOverview | undefined;
  creatorPerformanceOverview: {
    loading: boolean;
    data: CreatorPerformanceOverview;
  };
  showAddressRecommendationModal: boolean;
  addressRecommendation: AddressRecommendation | undefined;
  profilePersonalData: ProfilePersonalData | undefined;
  creatorsOverview: CreatorsOverview;
  creatorStatistics: CreatorStatistics;
  creators: {
    isLoading: boolean;
    list: CreatorLightResponse[];
  };
}

export const initialState: CreatorsState = {
  loggedInCreator: undefined,
  acceptTermsAndConditionsInProgress: false,
  selectedCreator: undefined,
  creatorPerformanceOverview: {
    loading: true,
    data: {
      streamedSeconds: 0,
      viewers: 0,
      currentLevel: {
        number: 0,
        min: 0,
        max: 0,
        commission: 0,
      },
      nextLevel: {
        number: 0,
        min: 0,
        max: 0,
        commission: 0,
      },
      levelProgress: 0,
      generatedSales: {
        amount: 0,
        currency: Currency.EUR,
      },
      earnings: {
        amount: 0,
        currency: Currency.EUR,
      },
      ordersCount: 0,
      viewsLive: 0,
      likes: 0,
      messages: 0,
      sampleOrderFeeMonth: undefined,
    },
  },
  showAddressRecommendationModal: false,
  addressRecommendation: undefined,
  profilePersonalData: undefined,
  creatorsOverview: {
    loading: false,
    queryParams: {
      pageSize: 10,
      pageNumber: 0,
      sortField: 'floorSales',
      sortOrder: 'desc',
      from: dayjs().format('yyyy-MM-dd'),
      to: dayjs().format('yyyy-MM-dd'),
      audience: [Audience.HELLO],
    },
    creators: [],
    hits: 0,
    pages: 0,
    tableFilter: {
      pagination: {
        pageSize: 10,
      },
    },
  },
  creatorStatistics: {
    followerCount: 0,
    showsStats: {
      likesCount: 0,
      viewsCount: 0,
    },
  },
  creators: {
    isLoading: false,
    list: [],
  },
};

export const fetchCreator = createAction<{ creatorId: string }>('creators/fetchCreator');
export const fetchLoggedInCreator = createAction('creators/fetchLoggedInCreator');
export const getCreatorPerformanceOverview = createAction('creators/getCreatorPerformanceOverview');
export const validateCreatorIban = createAction<{ iban: string }>('creators/validateCreatorIban');
export const navigateToCreatorDetailsPage = createAction<{ creatorId: string }>(
  'creators/navigateToCreatorDetailsPage'
);

export const saveNewCreator = createAction<AgencyAddCreatorDataUi>('creators/saveNewCreator');

export const createApprovedCreator = createAction<AdminAddCreatorDataUi>(
  'creators/createApprovedCreator'
);

export const updateCreatorDetails = createAction<ProfilePersonalData>(
  'creators/updateCreatorDetails'
);

export const updateCreatorShowImages = createAction<ShowImagesData>(
  'creators/updateCreatorShowImages'
);

export const approveCreator =
  createAction<{ creatorId: string; role: CreatorRole }>('creators/approveCreator');

export const validateAddress = createAction<ProfilePersonalData>('creators/validateAddress');

export const acceptTermsAndConditions = createAction('creators/acceptTermsAndConditions');

export const hideAddressRecommendation = createAction<void>('creators/hideAddressRecommendation');

export const shopSetupCompleted = createAction('creators/shopSetupCompleted');

export const samplesOrdered = createAction('creators/samplesOrdered');

export const samplesReceived = createAction('creators/samplesReceived');

export const onboardingCompleted = createAction('creators/onboardingCompleted');
export const amaOnboardingCompleted = createAction('creators/amaOnboardingCompleted');

export const resendWelcomeEmail = createAction<string>('creators/resendWelcomeEmail');

export const profileCompleted = createAction('creators/profileCompleted');

export const deleteCreatorAccount = createAction<void>('creators/deleteCreatorAccount');

export const getCreatorsOverview = createAction<QueryParams>('creators/getCreatorsOverview');

export const acceptMarketingConsent = createAction<boolean>('creators/acceptMarketingConsent');

export const fetchCreatorStatistics = createAction<{ creatorId: string }>(
  'creators/getCreatorStatistics'
);

export const fetchCreatorsList = createAction('creators/fetchCreatorsList');

export const creatorsSlice = createSlice({
  name: 'creators',
  initialState,
  reducers: {
    resetCreatorsState: () => initialState,
    setLoggedInCreator: (state, action: PayloadAction<LoggedInCreator>) => {
      state.loggedInCreator = action.payload;
    },
    setLoggedInCreatorShowImages: (state, action: PayloadAction<ShowImages>) => {
      if (state.loggedInCreator) {
        state.loggedInCreator.showImages = action.payload;
      }
    },
    setAcceptTermsAndConditionsInProgress: (state, action: PayloadAction<boolean>) => {
      state.acceptTermsAndConditionsInProgress = action.payload;
    },
    setSelectedCreator: (state, action: PayloadAction<CreatorDetailsOverview>) => {
      state.selectedCreator = action.payload;
    },
    clearSelectedCreator: state => {
      state.selectedCreator = undefined;
    },
    setCreatorPerformanceOverview: (state, action: PayloadAction<CreatorPerformanceOverview>) => {
      state.creatorPerformanceOverview.loading = false;
      state.creatorPerformanceOverview.data = action.payload;
    },
    setLoggedInCreatorBic: (state, action: PayloadAction<string | undefined>) => {
      state.loggedInCreator!.bic = action.payload;
    },
    setAddressRecommendation: (state, action: PayloadAction<AddressRecommendation>) => {
      state.showAddressRecommendationModal = true;
      state.addressRecommendation = action.payload;
    },
    clearAddressRecommendation: state => {
      state.showAddressRecommendationModal = false;
      state.addressRecommendation = undefined;
    },
    setProfilePersonalData: (state, action: PayloadAction<ProfilePersonalData>) => {
      state.profilePersonalData = action.payload;
    },
    setCreatorsOverview: (state, action: PayloadAction<Partial<CreatorsOverview>>) => {
      state.creatorsOverview = {
        ...state.creatorsOverview,
        ...action.payload,
      };
    },
    setCreatorStatistics: (state, action: PayloadAction<CreatorStatistics>) => {
      state.creatorStatistics = action.payload;
    },

    setCreatorsList: (state, action: PayloadAction<CreatorLightResponse[]>) => {
      state.creators.list = action.payload;
    },
    setCreatorsIsLoading: (state, action: PayloadAction<boolean>) => {
      state.creators.isLoading = action.payload;
    },
  },
});

export const {
  setLoggedInCreator,
  setLoggedInCreatorShowImages,
  resetCreatorsState,
  setSelectedCreator,
  clearSelectedCreator,
  setCreatorPerformanceOverview,
  setLoggedInCreatorBic,
  setAddressRecommendation,
  setAcceptTermsAndConditionsInProgress,
  clearAddressRecommendation,
  setProfilePersonalData,
  setCreatorsOverview,
  setCreatorStatistics,
  setCreatorsList,
  setCreatorsIsLoading,
} = creatorsSlice.actions;
export default creatorsSlice.reducer;

const selectCreatorsState = (state: RootState) => state[creatorsSlice.name];

export const selectPerformanceOverviewLoading = createSelector(
  selectCreatorsState,
  state => state?.creatorPerformanceOverview.loading
);

export const selectCreatorPerformanceOverview = createSelector(
  selectCreatorsState,
  state => state?.creatorPerformanceOverview.data
);

export const selectSelectedCreator = createSelector(
  selectCreatorsState,
  state => state.selectedCreator
);

export const selectCreatorStatistics = createSelector(
  selectCreatorsState,
  state => state.creatorStatistics
);

export const selectLoggedInCreator = createSelector(
  selectCreatorsState,
  state => state?.loggedInCreator
);

export const selectLoggedInCreatorShowImages = createSelector(
  selectCreatorsState,
  state => state?.loggedInCreator?.showImages
);

export const selectLoggedInCreatorAudience = createSelector(
  selectCreatorsState,
  state => state?.loggedInCreator?.audience
);

export const selectAcceptTermsAndConditionsInProgress = createSelector(
  selectCreatorsState,
  state => state?.acceptTermsAndConditionsInProgress ?? false
);

export const selectCreatorOnboarding = createSelector(
  selectLoggedInCreator,
  loggedInStreamer => loggedInStreamer?.onboarding
);

const selectUserHasAcceptedTermsAndConditions = createSelector(
  selectLoggedInCreator,
  loggedInStreamer => loggedInStreamer?.onboarding.hasAcceptedTermsAndConditions
);

export const selectCreatorHasCompletedProfile = createSelector(
  selectLoggedInCreator,
  loggedInStreamer => loggedInStreamer?.onboarding.hasCompletedProfile
);

export const selectSamplesReceived = createSelector(
  selectLoggedInCreator,
  loggedInStreamer => loggedInStreamer?.onboarding.isSamplesReceived
);

export const selectLoggedInCreatorNeedsToBeFetched = createSelector(
  selectUserIsCreator,
  selectLoggedInCreator,
  (isStreamer, loggedInCreator) => isStreamer === true && !loggedInCreator
);

export const selectCurrentOnboardingStepIndex = createSelector(
  selectLoggedInCreator,
  selectOnboardingIndicatorDialogStatus,
  (loggedInCreator, onboardingIndicatorDialogDisplay) => {
    const onboardingSteps = {
      hasCompletedProfile: loggedInCreator?.onboarding.hasCompletedProfile,
      isSamplesOrdered: loggedInCreator?.onboarding.isSamplesOrdered,
      isSamplesReceived: loggedInCreator?.onboarding.isSamplesReceived,
      isFirstShowCreated: loggedInCreator?.onboarding.isFirstShowCreated,
      isFirstShowStreamed: loggedInCreator?.onboarding.isFirstShowStreamed,
      isOnboardingCompleted: loggedInCreator?.onboarding.isOnboardingCompleted,
      onboardingIndicatorDialogDisplay: onboardingIndicatorDialogDisplay,
    };
    return Object.values(onboardingSteps).findIndex(stepValue => stepValue === false);
  }
);

/**
 * Indicates whether a user might need to accept the terms and conditions.
 * This selector returns true unless it can be established definitively that he either does not have to accept
 * due to his role or has already accepted the TnC.
 */
export const selectUserMightNeedToAcceptTermsAndConditions = createSelector(
  selectUserIsHelloStreamer,
  selectUserHasAcceptedTermsAndConditions,
  (isStreamer, hasAccepted) => {
    const isDefinitelyNotStreamer = isStreamer === false;
    const isStreamerAndHasAccepted = isStreamer === true && hasAccepted === true;
    return !isDefinitelyNotStreamer && !isStreamerAndHasAccepted;
  }
);

/**
 * Indicates whether the terms and conditions acceptance dialog should be displayed.
 * This selector returns true if it can be established definitively that the user is a streamer and has not yet
 * accepted the TnC.
 */
export const selectShowTermsAndConditionsDialog = createSelector(
  selectUserIsHelloStreamer,
  selectUserHasAcceptedTermsAndConditions,
  selectAuthActivationProgressStep,
  (isStreamer, hasAccepted, activationStep) =>
    isStreamer === true && hasAccepted === false && activationStep !== ActivationStep.ADDRESS_STEP
);

export const selectShowAddressRecommendationModal = createSelector(
  selectCreatorsState,
  state => state.showAddressRecommendationModal
);

export const selectAddressRecommendation = createSelector(
  selectCreatorsState,
  state => state.addressRecommendation
);

export const selectProfilePersonalData = createSelector(
  selectCreatorsState,
  state => state.profilePersonalData
);

export const selectStreamerCurrentLevel = createSelector(
  selectCreatorsState,
  state => state.creatorPerformanceOverview.data.currentLevel.number
);
export const selectCreatorsList = createSelector(selectCreatorsState, state => state.creators.list);
export const selectCreatorsIsLoading = createSelector(
  selectCreatorsState,
  state => state.creators.isLoading
);

export const selectProfilePersonalDataAddressRecommendation = createSelector(
  selectProfilePersonalData,
  selectAddressRecommendation,
  (
    profilePersonalData?: ProfilePersonalData,
    recommendation?: AddressRecommendation
  ): ProfilePersonalData | undefined => {
    if (profilePersonalData && recommendation) {
      return mergeRecommendationIntoProfile(profilePersonalData, recommendation);
    }

    return profilePersonalData;
  }
);

export const selectCreatorsOverview = (state: RootState) => state.creators.creatorsOverview;

export const selectCreatorMarketingConsent = (state: RootState) =>
  state.creators.loggedInCreator?.marketingConsent;

const mergeRecommendationIntoProfile = (
  profile: ProfilePersonalData,
  recommendation: AddressRecommendation
): ProfilePersonalData => {
  const filteredRecommendation = _.pick(recommendation, [
    'street',
    'streetNumber',
    'zipCode',
    'city',
  ]);

  return {
    ...profile,
    ...filteredRecommendation,
  };
};
