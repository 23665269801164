import { useSelector } from 'react-redux';
import { Audience } from '../common/types';
import {
  DEFAULT_CURTAIN_AFTER_IMAGE_URL,
  DEFAULT_CURTAIN_AFTER_IMAGE_URL_CLASSIC,
  DEFAULT_CURTAIN_BEFORE_IMAGE_URL,
  DEFAULT_CURTAIN_BEFORE_IMAGE_URL_CLASSIC,
} from '../constants/show';
import {
  selectLoggedInCreatorAudience,
  selectLoggedInCreatorShowImages,
} from '../creators/state/creatorsSlice';
import { ShowImages } from '../shows/model/shows';

export const useShowImages = (showImages?: ShowImages): ShowImages[] => {
  const creatorShowImages = useSelector(selectLoggedInCreatorShowImages);
  const creatorAudience = useSelector(selectLoggedInCreatorAudience);
  const isHelloAudience = creatorAudience === Audience.HELLO;

  const localShowImages: ShowImages = {
    curtainBeforeImageUrl:
      showImages?.curtainBeforeImageUrl ??
      creatorShowImages?.curtainBeforeImageUrl ??
      (isHelloAudience
        ? DEFAULT_CURTAIN_BEFORE_IMAGE_URL
        : DEFAULT_CURTAIN_BEFORE_IMAGE_URL_CLASSIC),
    curtainAfterImageUrl:
      showImages?.curtainAfterImageUrl ??
      creatorShowImages?.curtainAfterImageUrl ??
      (isHelloAudience ? DEFAULT_CURTAIN_AFTER_IMAGE_URL : DEFAULT_CURTAIN_AFTER_IMAGE_URL_CLASSIC),
    previewImageUrl: showImages?.previewImageUrl ?? creatorShowImages?.previewImageUrl,
  };

  return [localShowImages];
};
