import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import { PageableRequest } from '../../common/types';
import { FutureDateFilters } from '../../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { PastDateFilters } from '../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import t from '../../constants/translation';
import { LoggedInCreator } from '../../creators/model/creator';
import { fetchLoggedInCreatorHandler } from '../../creators/state/creatorsHandler';
import { fetchLoggedInCreator, selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import { trackStreamEnded } from '../../middlewares/reduxBeaconMiddleware/actions';
import { getErrorMessageOrDefault } from '../../notifications/state/notificationsHandler';
import {
  closeProgressDialog,
  errorOccurred,
  hideProgressDialog,
  openProgressDialog,
  openSuccessDialog,
  showAmaMessagesDialog,
  showMarketingConsentIfNeeded,
  showSnackbar,
} from '../../notifications/state/notificationsSlice';
import amasApi from '../../onDemandInteractions/amas/api/amasApi';
import { ProductTileInfo } from '../../products/model/productTileInfo';
import { SearchResponse } from '../../products/model/searchResponse';
import {
  addToProductsSelectionSelectedProducts,
  clearProductsSelection,
} from '../../products/state/productsSlice';
import { mapSearchResponseToProductTileInfos } from '../../products/utils/productTileInfoUtils';
import routePaths from '../../routes/routePaths';
import { put as sendPutRequest } from '../../shared/axiosClient';
import { sharedStorageService } from '../../storage/sharedStorageService';
import getBlobFromUrl from '../../utils/images/getBlobFromUrl';
import { parseError } from '../../utils/parseError/parseError';
import showsApi from '../api/showsApi';
import {
  ArchivedShow,
  AvailableShowTimeSlotsResponse,
  CreateShowResponse,
  CreatorPastShowsResponse,
  CreatorUpcomingShowsResponse,
  GeneratePreSignedShowUrlResponse,
  ShowDetailsResponse,
  ShowResponse,
  ShowsQueryParams,
  ShowUpdateRequest,
  StreamOwnerUpdateResponse,
  UpcomingShowsWithCreatorsResponse,
} from '../api/showsRequestResponse';
import {
  AddProductToShow,
  ChangeShowVisibility,
  ImageType,
  PastShowsWithCreatorsResponse,
  ProductPreview,
  ScheduleShowData,
  ShortLinkResponse,
  ShowDetails,
  ShowImageExtension,
  ShowUpdatePayload,
  StreamedBy,
  TagsResponse,
} from '../model/shows';
import { Voucher } from '../model/voucher';
import {
  addProductToShow,
  attachVoucherToShow,
  changeShowVisibility,
  checkForShowEndedTracking,
  clearAllProducts,
  closeShareModal,
  closeShowShareModal,
  createNewShow,
  delayDispatchOfShowCreatedEvent,
  deleteExpiredShow,
  deleteShow,
  deleteShowByAdmin,
  detachVoucherFromShow,
  endShow,
  fetchAmaMessages,
  fetchAvailableShowTimeSlot,
  fetchShow,
  fetchShowDetails,
  firstShowCreated,
  getApprovedTags,
  getCreatorArchivedShows,
  getCreatorPastShows,
  getCreatorUpcomingShows,
  getShowShorterLink,
  hasStreamerCreatedExactlyOneShow,
  HighlightProduct,
  highLightProducts,
  navigateToCreateShowPage,
  onRemoveProductForShowSchedule,
  onSelectProductForShowSchedule,
  pushHighLightedProductFailure,
  pushHighLightedProductSuccess,
  queryAdminPastShows,
  queryAdminUpcomingShows,
  QueryData,
  removeFromCreatorUpcomingShows,
  replaceShowScheduleProducts,
  searchForShowScheduleProducts,
  selectAdminPastShowsQueryData,
  selectAdminUpcomingShowsQueryData,
  selectCreatorPastShowsPageable,
  selectSelectedProducts,
  selectShowDetailsData,
  setAdminPastShows,
  setAdminPastShowsLoading,
  setAdminPastShowsOnlyLivePerformance,
  setAdminPastShowsOnlyLivePerformanceAction,
  setAdminPastShowsQueryData,
  setAdminUpcomingShows,
  setAdminUpcomingShowsLoading,
  setAdminUpcomingShowsQueryData,
  setAdminUpcomingShowVisibility,
  setApprovedTags,
  setAvailableShowTimeSlots,
  setCreatorArchivedShows,
  setCreatorPastShows,
  setCreatorPastShowsLoading,
  setCreatorUpcomingShows,
  setShortLinkSuccess,
  setShow,
  setShowDetails,
  setShowDetailsLoading,
  setShowDetailsVoucher,
  setShowProductDetails,
  setShowPublished,
  setShowScheduleProducts,
  setShowScheduleSearchResponse,
  setShowsOnlyLivePerformance,
  setShowsOnlyLivePerformanceAction,
  setShowTimeSlotsIsLoading,
  ShowScheduleProductsParams,
  updateShow,
  updateStreamedBy,
} from './showsSlice';

export function* fetchCreatorUpcomingShowsHandler(action?: PayloadAction<PageableRequest>) {
  try {
    let creator: LoggedInCreator | undefined = yield select(selectLoggedInCreator);
    const defaultPageable = {
      page: 0,
      pageSize: 10,
    };
    if (!creator) {
      yield call(fetchLoggedInCreatorHandler);
      creator = yield select(selectLoggedInCreator);
    }
    if (creator) {
      const creatorUpcomingShowsResponse: CreatorUpcomingShowsResponse = yield call(
        showsApi.getCreatorUpcomingShows,
        creator.id,
        action?.payload ?? defaultPageable
      );

      // Fetch products sold-out info
      const updatedProducts: ProductPreview[] = yield call(
        fetchSoldOutAndPriceInfoHandler,
        creatorUpcomingShowsResponse.shows.flatMap(show => show.products)
      );

      // Create a mapping of product ID to updated product
      const updatedProductMap = new Map(
        updatedProducts.map(product => [product.baseProductNo, product])
      );

      // Map updated products back to their respective shows
      const updatedShows = creatorUpcomingShowsResponse.shows.map(show => ({
        ...show,
        products: show.products.map(product => ({
          ...product,
          soldOut: updatedProductMap.get(product.baseProductNo)?.soldOut ?? false,
        })),
      }));

      yield put(setCreatorUpcomingShows({ ...creatorUpcomingShowsResponse, shows: updatedShows }));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchSoldOutAndPriceInfoHandler(products: ProductPreview[] | ProductTileInfo[]) {
  try {
    const baseProductNos = products.map(product => product.baseProductNo);

    if (baseProductNos.length) {
      const productsInfo: ProductTileInfo[] = yield call(
        showsApi.getProductsDetailsByBaseProductNos,
        baseProductNos
      );

      return products.map(product => {
        const productTileInfo = productsInfo.find(
          info => info.baseProductNo === product.baseProductNo
        );
        return {
          ...product,
          price: productTileInfo?.price,
          soldOut: productTileInfo?.outOfStock ?? false,
        };
      });
    }

    return products;
  } catch (err) {
    console.error('Failed to fetch products info:', err);
    return products;
  }
}

export function* fetchCreatorPastShowsHandler() {
  try {
    yield put(setCreatorPastShowsLoading(true));
    let creator: LoggedInCreator | undefined = yield select(selectLoggedInCreator);
    if (!creator) {
      yield call(fetchLoggedInCreatorHandler);
      creator = yield select(selectLoggedInCreator);
    }
    if (creator) {
      const pageable: ReturnType<typeof selectCreatorPastShowsPageable> = yield select(
        selectCreatorPastShowsPageable
      );
      const creatorPastShowsResponse: CreatorPastShowsResponse = yield call(
        showsApi.getCreatorPastShows,
        creator.id,
        pageable
      );
      yield put(setCreatorPastShows(creatorPastShowsResponse));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setCreatorPastShowsLoading(false));
  }
}

export function* fetchCreatorArchivedShowsHandler() {
  try {
    const archivedShows: ArchivedShow[] = yield call(showsApi.fetchArchivedShows);
    yield put(setCreatorArchivedShows(archivedShows));
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to fetch creator archived shows:',
        error: parseError(err),
      })
    );
  }
}

export function* setShowsOnlyLivePerformanceHandler(action: PayloadAction<boolean>) {
  try {
    yield put(setShowsOnlyLivePerformance(action.payload));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* setAdminPastShowsOnlyLivePerformanceHandler(action: PayloadAction<boolean>) {
  try {
    yield put(setAdminPastShowsOnlyLivePerformance(action.payload));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* createNewShowHandler(action: PayloadAction<ScheduleShowData>) {
  try {
    yield put(openProgressDialog('Erstelle Show...'));
    const { preview, ...showToCreate } = action.payload;

    const { id }: CreateShowResponse = yield call(showsApi.createNewShow, { ...showToCreate });

    // Show preview image
    if (preview?.changed && preview?.imageUrl) {
      // Create a pre-signed url + Upload the image to S3
      const { key }: GeneratePreSignedShowUrlResponse = yield call(
        uploadShowImage,
        id,
        preview.imageUrl,
        preview.imageType,
        preview.fileExtension
      );

      // Update the show with the uploaded image
      yield call(showsApi.setShowImage, id, key, preview.imageType);
    }

    yield put(showSnackbar({ text: 'Stream erfolgreich erstellt 🎉' }));

    yield put(fetchLoggedInCreator()); // Update onboarding steps

    yield put(clearProductsSelection());

    yield put(push(routePaths.creator.dashboard));

    yield put(clearAllProducts());
    yield put(showMarketingConsentIfNeeded());
    yield put(delayDispatchOfShowCreatedEvent({ title: showToCreate.title, id: id }));
  } catch (error: unknown) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when trying to create new show:',
        error: parseError(error),
      })
    );
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(closeProgressDialog());
  }
}

export function* endShowHandler(action: PayloadAction<ShowDetails>) {
  try {
    yield put(openProgressDialog('Beende Show...'));
    yield call(showsApi.endShow, action.payload.creatorDetails.id, action.payload.id);
    yield put(push(routePaths.hseEmployee.showDetails.replace(':showId', action.payload.id)));
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: `error occurred when trying to end show ${action.payload.id}`,
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(hideProgressDialog());
  }
}

export function* searchForShowScheduleProductsHandler(
  action: PayloadAction<ShowScheduleProductsParams>
) {
  try {
    const searchResponse: SearchResponse = yield call(
      showsApi.getProductsSearchResult,
      action.payload.query
    );

    const products = mapSearchResponseToProductTileInfos(searchResponse);

    // Fetch products sold-out info
    const updatedProducts: ProductTileInfo[] = yield call(
      fetchSoldOutAndPriceInfoHandler,
      products
    );

    yield put(setShowScheduleSearchResponse(updatedProducts));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* triggerAdminUpcomingShowsRequestHandler() {
  const queryData: QueryData<FutureDateFilters> = yield select(selectAdminUpcomingShowsQueryData);

  // We want to send a request if all search params are set.
  // Here we need to wait for dateFrom and dateTo because their values are set by DateFilter component and are not set initially (needs to be calculated, because only selectedFilter is known).
  if (queryData.dateFrom && queryData.dateTo) {
    const searchQueryParams: ShowsQueryParams = {
      dateFrom: queryData.dateFrom,
      dateTo: queryData.dateTo,
      sortField: queryData.sortField,
      searchTerm: queryData.searchTerm,
      sortOrder: queryData.sortOrder,
      page: queryData.page,
      pageSize: queryData.pageSize,
      audience: queryData.audience,
      streamedBy: queryData.streamedBy,
      excludeEarlyBirds: queryData.excludeEarlyBirds,
    };
    yield put(queryAdminUpcomingShows(searchQueryParams));
  }
}

export function* triggerAdminPastShowsRequestHandler() {
  const queryData: QueryData<PastDateFilters> = yield select(selectAdminPastShowsQueryData);

  // We want to send a request if all search params are set.
  // Here we need to wait for dateFrom and dateTo because their values are set by DateFilter component and are not set initially (needs to be calculated, because only selectedFilter is known).
  if (queryData.dateFrom && queryData.dateTo) {
    const searchQueryParams: ShowsQueryParams = {
      dateFrom: queryData.dateFrom,
      dateTo: queryData.dateTo,
      sortField: queryData.sortField,
      searchTerm: queryData.searchTerm,
      sortOrder: queryData.sortOrder,
      page: queryData.page,
      pageSize: queryData.pageSize,
      audience: queryData.audience,
    };
    yield put(queryAdminPastShows(searchQueryParams));
  }
}

export function* queryAdminUpcomingShowsHandler(action: PayloadAction<ShowsQueryParams>) {
  try {
    yield put(setAdminUpcomingShowsLoading(true));
    const searchResponse: UpcomingShowsWithCreatorsResponse = yield call(
      showsApi.queryAdminUpcomingShows,
      action.payload
    );

    // Fetch products sold-out and price info
    const updatedProducts: ProductPreview[] = yield call(
      fetchSoldOutAndPriceInfoHandler,
      searchResponse.shows.flatMap(show => show.products)
    );

    // Create a mapping of product ID to updated product
    const updatedProductMap = new Map(
      updatedProducts.map(product => [product.baseProductNo, product])
    );

    // Map updated products back to their respective shows
    const updatedShows = searchResponse.shows.map(show => ({
      ...show,
      products: show.products.map(product => {
        const currentProduct = updatedProductMap.get(product.baseProductNo);
        return {
          ...product,
          soldOut: currentProduct?.soldOut ?? false,
          price: currentProduct?.price,
        };
      }),
    }));

    yield put(setAdminUpcomingShows({ ...searchResponse, shows: updatedShows }));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setAdminUpcomingShowsLoading(false));
  }
}

export function* queryAdminPastShowsHandler(action: PayloadAction<ShowsQueryParams>) {
  try {
    yield put(setAdminPastShowsLoading(true));
    const searchResponse: PastShowsWithCreatorsResponse = yield call(
      showsApi.queryAdminPastShows,
      action.payload
    );
    yield put(setAdminPastShows(searchResponse));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setAdminPastShowsLoading(false));
  }
}

export function* onSelectProductForShowScheduleHandler(action: PayloadAction<ProductTileInfo>) {
  try {
    const selectedProducts: ProductTileInfo[] = yield select(selectSelectedProducts);
    const alreadySelected = selectedProducts.find(
      product => product.baseProductNo === action.payload.baseProductNo
    );
    if (!alreadySelected) {
      yield put(setShowScheduleProducts(action.payload));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* onRemoveProductForShowScheduleHandler(action: PayloadAction<ProductTileInfo>) {
  try {
    const selectedProducts: ProductTileInfo[] = yield select(selectSelectedProducts);
    const alreadySelected = selectedProducts.find(
      product => product.baseProductNo === action.payload.baseProductNo
    );
    if (alreadySelected) {
      const newProductsList = selectedProducts.filter(
        e => e.baseProductNo !== action.payload.baseProductNo
      );
      yield put(replaceShowScheduleProducts(newProductsList));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchShowHandler(action: PayloadAction<string>) {
  try {
    const showId = action.payload;
    const show: ShowResponse = yield call(showsApi.getShowById, showId);
    const baseProductNos = show.products?.map(p => p.baseProductNo);
    if (baseProductNos?.length) {
      const products: ProductTileInfo[] = yield call(
        showsApi.getProductsDetailsByBaseProductNos,
        baseProductNos
      );
      yield put(addToProductsSelectionSelectedProducts(products));
    } else {
      yield put(addToProductsSelectionSelectedProducts([]));
    }
    yield put(setShow(show));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* uploadShowImage(
  showId: string,
  imageUrl: string,
  imageType: ImageType,
  fileExtension: ShowImageExtension
) {
  try {
    const preSignedUrlResponse: GeneratePreSignedShowUrlResponse = yield call(
      showsApi.generatePreSignedUrl,
      showId,
      imageType,
      fileExtension
    );
    const previewImageData: Blob = yield call(getBlobFromUrl, imageUrl);
    yield call(sendPutRequest, preSignedUrlResponse.preSignedUrl, previewImageData);

    return preSignedUrlResponse;
  } catch (err) {
    yield put(errorOccurred(err as Error));
    yield put(push(routePaths.creator.updateShow.replace(':showId', showId)));
  }
}

export function* updateShowHandler(action: PayloadAction<ShowUpdatePayload>) {
  try {
    const { showId, request } = action.payload;
    let previewKey: string | undefined;

    yield put(openProgressDialog('Aktualisiere Show...'));

    if (request.preview?.changed && !!request.preview?.imageUrl) {
      const { key }: GeneratePreSignedShowUrlResponse = yield call(
        uploadShowImage,
        showId,
        request.preview.imageUrl,
        request.preview.imageType,
        request.preview.fileExtension
      );
      previewKey = key;
    }

    const updateRequest: ShowUpdateRequest = {
      title: request.title,
      scheduledStartAt: request.scheduledStartAt,
      baseProductsNo: request.baseProductsNo,
      tags: request.tags,
      imageKeys: { preview: previewKey },
      header: request.header,
    };

    const updatedShow: ShowResponse = yield call(showsApi.updateShow, showId, updateRequest);
    yield put(setShow(updatedShow));
    yield put(showSnackbar({ text: 'Stream erfolgreich geändert 🎉' }));
    yield put(clearProductsSelection());
    yield put(push(routePaths.creator.dashboard));
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: `Error occurred when trying to update show ${action.payload.showId}`,
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(closeProgressDialog());
  }
}

export function* navigateToCreateShowPageHandler() {
  const userRoles: Role[] = yield select(selectUserRoles);
  const route = userRoles.includes(Role.STREAMER_HELLO) ? routePaths.creator.addShow : '';
  const path = generatePath(route);
  yield put(push(path));
}

export function* getShowShorterLinkHandler(action: PayloadAction<string>) {
  try {
    yield put(openProgressDialog('Erstelle Link'));
    const showId = action.payload;
    const data: ShortLinkResponse = yield call(showsApi.getShortLink, showId);
    if (navigator.share && navigator.canShare({ url: data.shortLink })) {
      navigator.share({ url: data.shortLink });
    } else {
      yield put(setShortLinkSuccess(data.shortLink));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(closeProgressDialog());
  }
}

export function* closeShowShareModalHandler() {
  yield put(closeShareModal());
}

export function* delayDispatchOfShowCreatedEventHandler(
  action: PayloadAction<{ title: string; id: string }>
) {
  // There is a bug in the hotjar. If two events are dispatched directly one after another, hotjar throws an error.
  // That's why we have to delay showCreatedEvent.
  // Otherwise it will be dispatched right after trackPageImpressionEvent which is dispatched on each location change...
  yield delay(3000);
  const isFirstShow: boolean = yield select(hasStreamerCreatedExactlyOneShow);

  if (isFirstShow) {
    yield put(firstShowCreated({ title: action.payload.title, id: action.payload.id }));
  }
}

export function* fetchShowDetailsHandler(action: PayloadAction<string>) {
  try {
    yield put(setShowDetailsLoading(true));
    const showId = action.payload;
    const showDetailsResponse: ShowDetailsResponse = yield call(showsApi.getShowDetails, showId);
    yield* handleShowDetailResponse(showDetailsResponse);
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setShowDetailsLoading(false));
  }
}

function* handleShowDetailResponse(showDetailsResponse: ShowDetailsResponse) {
  yield put(setShowDetails(showDetailsResponse));
  yield put(setShowDetailsVoucher({ loading: false, errorKey: undefined }));
  if (showDetailsResponse.products.length) {
    const baseProductNos = showDetailsResponse.products.map(product => product.baseProductNo);
    const products: ProductTileInfo[] = yield call(
      showsApi.getProductsDetailsByBaseProductNos,
      baseProductNos
    );
    yield put(setShowProductDetails(products));
  }
}

export function* highLightProductsHandler(action: PayloadAction<HighlightProduct>) {
  try {
    yield call(showsApi.highlightProducts, action.payload.showId, action.payload.baseProductNos);
    yield put(pushHighLightedProductSuccess());
  } catch (err) {
    yield put(
      pushHighLightedProductFailure(
        getErrorMessageOrDefault(
          err as AxiosError,
          'Synchronisation von Highlightprodukten fehlgeschlagen!'
        )
      )
    );
  }
}

export function* changeShowVisibilityHandler(action: PayloadAction<ChangeShowVisibility>) {
  const { showId, isPublished } = action.payload;
  try {
    yield put(setShowDetailsLoading(true));
    yield call(showsApi.postShowVisibility, showId, isPublished);
    yield put(setShowPublished(isPublished));
    if (action.payload.updateUpcomingShowsOverview) {
      yield put(setAdminUpcomingShowVisibility({ showId, isPublished }));
    }
    select();
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setShowDetailsLoading(false));
  }
}

export function* getApprovedTagsHandler() {
  try {
    const tags: TagsResponse = yield call(showsApi.getApprovedTags);
    yield put(setApprovedTags(tags.tags));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* deleteShowHandler(action: PayloadAction<string>) {
  yield call(showsApi.deleteShow, action.payload);
  yield put(removeFromCreatorUpcomingShows(action.payload));
}

export function* deleteShowByAdminHandler(
  action: PayloadAction<{ showId: string; redirect?: boolean }>
) {
  try {
    yield put(setShowDetailsLoading(true));
    yield call(showsApi.deleteShowByAdmin, action.payload.showId);

    if (action.payload.redirect) {
      const upcomingShowsOverviewNewDesign = sharedStorageService.getItem(
        LocalStorageKeys.upcomingShowsOverviewNewDesign
      );
      yield put(
        push(
          // TODO: Remove after https://xlibxli.atlassian.net/browse/SC-15365 is done
          upcomingShowsOverviewNewDesign === 'true'
            ? routePaths.hseEmployee.upcomingShowsOverviewNew
            : routePaths.hseEmployee.upcomingShowsOverview
        )
      );
    } else {
      yield call(triggerAdminUpcomingShowsRequestHandler);
    }

    yield put(
      showSnackbar({
        text: t.creators.show['Stream successfully deleted'],
      })
    );
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setShowDetailsLoading(false));
  }
}

export function* addProductToShowHandler(action: PayloadAction<AddProductToShow>) {
  const payload = action.payload;
  try {
    yield put(setShowDetailsLoading(true));
    const showDetailsResponse: ShowDetailsResponse = yield call(
      showsApi.addProductsToShow,
      payload.showId,
      payload.baseProductsNo
    );
    yield* handleShowDetailResponse(showDetailsResponse);
    yield put(clearAllProducts());
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setShowDetailsLoading(false));
  }
}

export function* checkForShowEndedTrackingHandler() {
  try {
    const streamEnded = sharedStorageService.getItem(LocalStorageKeys.streamEnded);
    const ucUserInteraction = sharedStorageService.getItem(LocalStorageKeys.UcUserInteraction);
    if (streamEnded) {
      yield put(
        trackStreamEnded({
          showId: streamEnded,
          consent: Boolean(ucUserInteraction),
        })
      );
      sharedStorageService.removeItem(LocalStorageKeys.streamEnded);
      const amaResponsesCount: number = yield call(
        amasApi.getAmaResponsesCountForShow,
        streamEnded
      );
      if (amaResponsesCount) {
        yield put(
          showAmaMessagesDialog({
            isOpen: true,
            showId: streamEnded,
            amaResponsesCount,
          })
        );
      }
    }
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: 'error occurred when checking stream ended tracking',
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchAmaMessagesHandler() {
  try {
    const streamEnded = sharedStorageService.getItem(LocalStorageKeys.streamEnded);
    const ucUserInteraction = sharedStorageService.getItem(LocalStorageKeys.UcUserInteraction);
    if (streamEnded) {
      yield put(
        trackStreamEnded({
          showId: streamEnded,
          consent: Boolean(ucUserInteraction),
        })
      );
      sharedStorageService.removeItem(LocalStorageKeys.streamEnded);
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

function* attachVoucherToShowHandler(
  action: PayloadAction<{ showId: string; voucherCode: string }>
) {
  try {
    yield put(setShowDetailsVoucher({ loading: true, errorKey: undefined }));
    const showId = action.payload.showId;
    const voucherCode = action.payload.voucherCode;
    const voucher: Voucher = yield call(showsApi.attachVoucherToShow, showId, voucherCode);
    const showDetails: ShowDetails = yield select(selectShowDetailsData)!;
    yield put(
      setShowDetails({
        ...showDetails,
        vouchers: [voucher],
      })
    );
    yield put(
      setShowDetailsVoucher({
        loading: false,
        errorKey: undefined,
      })
    );
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<{ key: string }>;
      yield put(
        setShowDetailsVoucher({
          errorKey: axiosError.response?.data.key,
          loading: false,
        })
      );
    } else {
      yield put(errorOccurred(err as Error));
    }
  }
}

function* detachVoucherFromShowHandler(action: PayloadAction<{ showId: string }>) {
  try {
    yield put(setShowDetailsVoucher({ loading: true, errorKey: undefined }));
    const showId = action.payload.showId;
    yield call(showsApi.detachVoucherFromShow, showId);
    const showDetails: ShowDetails = yield select(selectShowDetailsData)!;
    yield put(
      setShowDetails({
        ...showDetails,
        vouchers: [],
      })
    );
    yield put(
      setShowDetailsVoucher({
        loading: false,
        errorKey: undefined,
      })
    );
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      const axiosError = err as AxiosError<{ key: string }>;
      yield put(
        setShowDetailsVoucher({
          errorKey: axiosError.response?.data.key,
          loading: false,
        })
      );
    } else {
      yield put(errorOccurred(err as Error));
    }
  }
}

export function* deleteExpiredShowHandler(action: PayloadAction<string>) {
  try {
    yield call(showsApi.deleteShow, action.payload);
    yield put(removeFromCreatorUpcomingShows(action.payload));
    yield put(
      openSuccessDialog({
        title: t.creators.show['Expired stream'],
        text: t.creators.show['Successfully deleted'],
      })
    );
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchAvailableShowTimeSlotsHandler(action: PayloadAction<Date>) {
  try {
    const showDate = action.payload;
    yield put(setShowTimeSlotsIsLoading(true));
    const availableShowTimeSlotsResponse: AvailableShowTimeSlotsResponse = yield call(
      showsApi.fetchAvailableShowTimeSlots,
      showDate.toISOString()
    );
    yield put(setAvailableShowTimeSlots(availableShowTimeSlotsResponse.slots));
    yield put(setShowTimeSlotsIsLoading(false));
  } catch (err) {
    yield put(setShowTimeSlotsIsLoading(false));
    yield put(errorOccurred(err as Error));
  }
}

export function* updateStreamedByHandler(
  action: PayloadAction<{ showId: string; streamedBy: StreamedBy }>
) {
  try {
    const showDetailsResponse: ShowDetailsResponse = yield select(selectShowDetailsData);
    const streamedByResponse: StreamOwnerUpdateResponse = yield call(
      showsApi.updateStreamedBy,
      action.payload.showId,
      action.payload.streamedBy
    );
    yield put(
      setShowDetails({
        ...showDetailsResponse,
        streamedBy: streamedByResponse.streamedBy,
      })
    );
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* watcherShowsSagas() {
  yield takeLatest(createNewShow.type, createNewShowHandler);
  yield takeLatest(searchForShowScheduleProducts.type, searchForShowScheduleProductsHandler);
  yield takeLatest(onSelectProductForShowSchedule.type, onSelectProductForShowScheduleHandler);
  yield takeLatest(onRemoveProductForShowSchedule.type, onRemoveProductForShowScheduleHandler);
  yield takeLatest(fetchShow.type, fetchShowHandler);
  yield takeLatest(updateShow.type, updateShowHandler);
  yield takeLatest(setShowsOnlyLivePerformanceAction.type, setShowsOnlyLivePerformanceHandler);
  yield takeLatest(navigateToCreateShowPage.type, navigateToCreateShowPageHandler);
  yield takeLatest(getShowShorterLink.type, getShowShorterLinkHandler);
  yield takeLatest(closeShowShareModal.type, closeShowShareModalHandler);
  yield takeLatest(delayDispatchOfShowCreatedEvent.type, delayDispatchOfShowCreatedEventHandler);
  yield takeLatest(fetchShowDetails.type, fetchShowDetailsHandler);
  yield takeLatest(queryAdminUpcomingShows.type, queryAdminUpcomingShowsHandler);
  yield takeLatest(setAdminUpcomingShowsQueryData.type, triggerAdminUpcomingShowsRequestHandler);
  yield takeLatest(queryAdminPastShows.type, queryAdminPastShowsHandler);
  yield takeLatest(setAdminPastShowsQueryData.type, triggerAdminPastShowsRequestHandler);
  yield takeLatest(endShow.type, endShowHandler);
  yield takeLatest(
    setAdminPastShowsOnlyLivePerformanceAction.type,
    setAdminPastShowsOnlyLivePerformanceHandler
  );
  yield takeLatest(highLightProducts.type, highLightProductsHandler);
  yield takeLatest(changeShowVisibility.type, changeShowVisibilityHandler);
  yield takeLatest(getApprovedTags.type, getApprovedTagsHandler);
  yield takeLatest(addProductToShow.type, addProductToShowHandler);
  yield takeLatest(deleteShow.type, deleteShowHandler);
  yield takeLatest(checkForShowEndedTracking.type, checkForShowEndedTrackingHandler);
  yield takeLatest(getCreatorUpcomingShows.type, fetchCreatorUpcomingShowsHandler);
  yield takeLatest(getCreatorPastShows.type, fetchCreatorPastShowsHandler);
  yield takeLatest(getCreatorArchivedShows.type, fetchCreatorArchivedShowsHandler);
  yield takeLatest(fetchAmaMessages.type, fetchAmaMessagesHandler);
  yield takeLatest(attachVoucherToShow.type, attachVoucherToShowHandler);
  yield takeLatest(detachVoucherFromShow.type, detachVoucherFromShowHandler);
  yield takeLatest(deleteExpiredShow.type, deleteExpiredShowHandler);
  yield takeLatest(updateStreamedBy.type, updateStreamedByHandler);
  yield takeLatest(fetchAvailableShowTimeSlot.type, fetchAvailableShowTimeSlotsHandler);
  yield takeLatest(deleteShowByAdmin.type, deleteShowByAdminHandler);
}
