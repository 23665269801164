import { Theme, useMediaQuery } from '@mui/material';
import DesktopProfileLockInfoTooltip from './DesktopProfileLockInfoTooltip/DesktopProfileLockInfoTooltip';
import MobileProfileLockInfoTooltip from './MobileProfileLockInfoTooltip/MobileProfileLockInfoTooltip';

const title = 'Diese Informationen werden nicht in deinem öffentlichen Profil angezeigt.';

function ProfileLockInfoIcon() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return isMobile ? (
    <MobileProfileLockInfoTooltip title={title} />
  ) : (
    <DesktopProfileLockInfoTooltip title={title} />
  );
}

export default ProfileLockInfoIcon;
