import {
  AnimatedCheckMark,
  IconChatBox,
  IconCustomerService,
  IconTrash,
} from '@hse24/shared-components';
import { Popover } from '@mui/material';
import React, { ReactElement } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Icons, { SvgIcon } from '../../../../assets';
import useCopyToClipboard from '../../../../components/CopyToClipboard/useCopyToClipboard';
import Icon from '../../../../components/Icon/Icon';
import Config from '../../../../config/config';
import { featureFlagValue } from '../../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../../config/FeatureFlags';
import t from '../../../../constants/translation';
import routePaths from '../../../../routes/routePaths';
import { UpcomingShowWithCreator } from '../../../api/showsRequestResponse';
import styles from './ShowMenuOptions.module.scss';

interface Props {
  show: UpcomingShowWithCreator;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleDelete: () => void;
  handleChangeVisibility: () => void;
  testId?: string;
}

interface MenuItemProps {
  child: ReactElement;
  text: string;
  onClick: () => void;
  color?: string;
}

interface MenuITemIconProps {
  icon: SvgIcon;
  color?: string;
}

const MenuItemIcon = ({ icon, color }: MenuITemIconProps) => {
  return <Icon icon={icon} style={{ color: color ?? 'black' }} />;
};

const MenuItem = ({ child, text, onClick, color }: MenuItemProps) => {
  return (
    <div onClick={onClick} className={styles.menu_item} data-testid="show-menu-options-item">
      {child}
      <span className={styles.menu_item_text} style={{ color: color ?? 'black' }}>
        {text}
      </span>
    </div>
  );
};

const ShowMenuOptions = ({
  show,
  isOpen,
  anchorEl,
  handleClose,
  handleDelete,
  handleChangeVisibility,
  testId,
}: Props) => {
  const { id: showId, products } = show;
  const isInApp = featureFlagValue(AvailableFlag.isInApp);
  const history = useHistory();
  const [isCopied, copyToClipboard] = useCopyToClipboard(2500);

  const navigateToShowDetails = () => {
    const path = generatePath(routePaths.hseEmployee.showDetails, { showId });
    history.push(path);
  };

  const handleToModerationPage = () => {
    const path = generatePath(routePaths.hseEmployee.showModeration, { showId });
    isInApp ? history.push(path) : window.open(path);
  };

  const handleOpenChat = () => {
    const path = generatePath(routePaths.creator.streamChat, { streamId: showId });
    isInApp ? history.push(path) : window.open(path);
  };

  const handleCopySkus = () => {
    copyToClipboard(products.map(p => p.baseProductNo).join(','));
  };

  const handleOpenInWSV = () => {
    const path = `${Config.env.baseUrl}/wsv/shows/${showId}`;
    window.open(path, '_blank');
  };

  const onDeleteIconClicked = () => {
    handleClose();
    handleDelete();
  };

  const onVisibilityChangeIconClicked = () => {
    handleClose();
    handleChangeVisibility();
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: '8px',
          boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.23)',
        },
      }}
      data-testid={testId}
    >
      <div className={styles.menu_stack}>
        <MenuItem
          child={<MenuItemIcon icon={Icons.pencilIcon} />}
          text={t.creators.show.Edit}
          onClick={navigateToShowDetails}
        />
        <MenuItem
          child={<IconChatBox />}
          text={t.creators.show.openChat}
          onClick={handleOpenChat}
        />
        <MenuItem
          child={<IconCustomerService />}
          text={t.creators.show['Moderator view']}
          onClick={handleToModerationPage}
        />
        <MenuItem
          child={
            isCopied ? (
              <AnimatedCheckMark className={styles.check_mark} />
            ) : (
              <MenuItemIcon icon={Icons.marketplaceIcon} />
            )
          }
          text={t.admins.upcomingShowsOverview['Copy base products numbers']}
          onClick={handleCopySkus}
        />
        <MenuItem
          child={<MenuItemIcon icon={Icons.externalLinkIcon} />}
          text={t.admins.upcomingShowsOverview['View in the store']}
          onClick={handleOpenInWSV}
        />
        <MenuItem
          child={
            <MenuItemIcon
              icon={show.isPublished ? Icons.eyesViewOffInvisibleIcon : Icons.eyesViewOnVisibleIcon}
            />
          }
          text={
            show.isPublished ? t.creators.show['Hide stream'] : t.creators.show['Publish stream']
          }
          onClick={onVisibilityChangeIconClicked}
        />
        <MenuItem
          child={<IconTrash color={'#BE0A14'} />}
          text={t.creators.show['Delete Stream']}
          onClick={onDeleteIconClicked}
          color={'#BE0A14'}
        />
      </div>
    </Popover>
  );
};

export default ShowMenuOptions;
