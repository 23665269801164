import { Audience, FloorSale, QueryParams } from '../../common/types';
import { DateRangeWithSelectedFilter } from '../../components/DateFilter/dateFilterTypes';
import { ShowImages } from '../../shows/model/shows';
import { CreatorsOverviewResponse } from '../api/requestResponse';
import { Salutation } from '../components/Profile/ProfilePersonalData';
import { TableFilter } from '../state/creatorsSlice';
import { SocialMediaPlatform } from './socialMediaPlatform';

export enum CreatorStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
}

export enum CreatorRole {
  STREAMER = 'STREAMER',
  INFLUENCER = 'INFLUENCER',
}

export const displayName = (role: CreatorRole): string => {
  switch (role) {
    case CreatorRole.STREAMER:
      return 'Streamer';
    case CreatorRole.INFLUENCER:
      return 'Influencer';
  }
};

export type SocialMediaAccountsWithUsername = {
  [key in Lowercase<`${SocialMediaPlatform}`>]?: string;
};

export interface CreatorOnboarding {
  hasAcceptedTermsAndConditions: boolean;
  hasCompletedProfile: boolean;
  isShopSetupComplete: boolean;
  isSamplesOrdered: boolean;
  isSamplesReceived: boolean;
  isFirstShowCreated: boolean;
  isFirstShowStreamed: boolean;
  isOnboardingCompleted: boolean;
}

export interface MarketingConsent {
  hasAccepted: boolean;
  lastDialogDisplayTime?: string;
}

export interface CreatorVoucher {
  id: string;
  code: string;
  minValue: number;
  maxValue: number;
  validityStartTime: string;
  validityEndTime: string;
  percentage: number;
}

export interface LoggedInCreator {
  id: string;
  email: string;
  description?: string;
  name: string;
  status: CreatorStatus;

  salutation?: Salutation;
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  phoneNumber?: string;
  birthDate?: string;

  iban?: string;
  bic?: string;
  vatId?: string;
  isLiableToVat?: boolean;
  taxId?: string;

  onboarding: CreatorOnboarding;
  isAmaOnboardingCompleted: boolean;

  socialMediaAccounts?: SocialMediaAccountsWithUsername;
  profileImageUrl?: string;
  showImages?: ShowImages;
  vouchers?: CreatorVoucher[];

  marketingConsent: MarketingConsent;

  audience: Audience;
}

export interface CreatorDetailsOverview {
  id: string;
  name: string;
  email: string;
  agencyName?: string;
  onboarding: CreatorOnboarding;
  socialMediaAccounts?: SocialMediaAccountsWithUsername;
  audience: Audience[];
  marketingConsent: MarketingConsent;
}

export interface CreatorOverview {
  id: string;
  name: string;
  email: string;
  role: CreatorRole;
  status: CreatorStatus;
  approvedAt?: string;
  agencyName?: string;
  streamedSeconds: number;
  floorSales: FloorSale;
  showsFloorSales: FloorSale;
  postsFloorSales: FloorSale;
  level: number;
  onboarding: CreatorOnboarding;
  audience: Audience[];
  marketingConsent: MarketingConsent;
}

export type OnboardingStatus = Pick<
  CreatorOnboarding,
  | 'hasAcceptedTermsAndConditions'
  | 'hasCompletedProfile'
  | 'isFirstShowCreated'
  | 'isFirstShowStreamed'
  | 'isSamplesOrdered'
> & { hasHSEApproved: boolean };

export interface CreatorPerformanceOverviewLevel {
  number: number;
  min: number;
  max: number;
  commission: number;
}

export interface CreatorPerformanceOverview {
  streamedSeconds: number;
  viewers: number;
  currentLevel: CreatorPerformanceOverviewLevel;
  nextLevel?: CreatorPerformanceOverviewLevel;
  levelProgress: number;
  generatedSales: FloorSale;
  earnings: FloorSale;
  ordersCount: number;
  viewsLive: number;
  likes: number;
  messages: number;
  sampleOrderFeeMonth?: number;
}

export enum CreatorImageType {
  PROFILE = 'PROFILE',
  CURTAIN_BEFORE = 'CURTAIN_BEFORE',
  CURTAIN_AFTER = 'CURTAIN_AFTER',
  PREVIEW = 'PREVIEW',
}

export interface CreatorBusinessData {
  iban: string;
  vatId?: string;
  isLiableToVat: boolean;
  taxId?: string;
}

export interface CreatorsOverview extends CreatorsOverviewResponse {
  loading: boolean;
  queryParams: QueryParams;
  tableFilter?: TableFilter;
  dateFilter?: DateRangeWithSelectedFilter;
  audienceFilter?: Audience[];
}

export interface CreatorStatistics {
  followerCount: number;
  showsStats: ShowStats;
}

interface ShowStats {
  viewsCount: number;
  likesCount: number;
}
