import { Box, Divider } from '@mui/material';
import AccessData from '../../../auth/components/AccessData/AccessData';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';

const CreatorAccountPage = () => {
  const isApp = featureFlagValue(AvailableFlag.isInApp);
  return (
    <>
      <AccessData />
      <Box mt={3} mb={3}>
        <Divider />
      </Box>

      {isApp && <DeleteAccount />}
    </>
  );
};

export default CreatorAccountPage;
