import { Button } from '@mui/material';
import cx from 'classnames';
import t from '../../../../../../constants/translation';
import DaySelectionPreview from '../DaySelectionPreview/DaySelectionPreview';
import styles from './DayActions.module.scss';
interface DayActionsProps {
  showDate: Date;
  className?: string;
  isValid: boolean;
  handleValid: () => void;
  handleCancel: () => void;
  shouldDisplayCancelButton: boolean;
}

const DayActions = ({
  handleValid,
  handleCancel,
  showDate,
  className,
  isValid,
  shouldDisplayCancelButton,
}: DayActionsProps) => {
  return (
    <div data-testid="day-actions" className={cx(className, styles.actions)}>
      {isValid && (
        <div className={styles.selected_date}>
          <DaySelectionPreview day={showDate} shouldDisplayFullDate={true} />
        </div>
      )}
      <Button
        data-testid="confirm-start-time"
        disabled={!isValid}
        variant="contained"
        onClick={handleValid}
        className={styles.primary_button}
        color="primary"
        fullWidth={true}
      >
        {t.creators.show.confirmStartTime}
      </Button>
      {shouldDisplayCancelButton && (
        <Button
          data-testid="cancel-start-time"
          onClick={handleCancel}
          fullWidth={true}
          variant="outlined"
          color="secondary"
        >
          {t.common.Cancel}
        </Button>
      )}
    </div>
  );
};

export default DayActions;
