import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener, IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';

interface MobileTaxInfoTooltipProps {
  title: string;
}

const MobileCustomizedTooltip = withStyles({
  tooltip: {
    maxWidth: '600px',
    whiteSpace: 'pre-wrap',
    fontSize: 12,
  },
})(Tooltip);

const useStyles = makeStyles(() =>
  createStyles({
    closeIcon: {
      textAlign: 'right',
      marginBottom: 8,
    },
  })
);

const MobileTooltip = ({ title }: MobileTaxInfoTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <MobileCustomizedTooltip
        PopperProps={{
          disablePortal: true,
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title={
          <>
            <div
              className={classes.closeIcon}
              onClick={() => setIsOpen(false)}
              data-testid="close-icon"
            >
              <CloseIcon />
            </div>
            <div>{title}</div>
          </>
        }
        placement="bottom"
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton
          data-testid="mobile-info-icon-with-btn"
          onClick={() => setIsOpen(!isOpen)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <InfoIcon />
        </IconButton>
      </MobileCustomizedTooltip>
    </ClickAwayListener>
  );
};

export default MobileTooltip;
