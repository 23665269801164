import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import cx from 'classnames';
import dayjs from 'dayjs';
import t from '../../../../constants/translation';
import styles from '../ScheduleShowForm.module.scss';

interface ShowStartTimePickerProps {
  onClick: () => void;
  scheduledStartAt: string;
  hasError: boolean;
}

const ShowStartTimePicker = ({ scheduledStartAt, onClick, hasError }: ShowStartTimePickerProps) => {
  const className = cx({
    [styles.card]: !hasError,
    [styles.card_error]: hasError,
  });
  return (
    <Paper onClick={onClick} className={className} elevation={0}>
      <CalendarTodayIcon className={styles.icon} />
      {scheduledStartAt ? (
        <Box textAlign="center">
          <Typography variant="h6" fontWeight="bold">
            {dayjs(scheduledStartAt).locale('de').format('HH:mm')}
          </Typography>
          <Typography variant="body1">
            {dayjs(scheduledStartAt).locale('de').format('dddd, D. MMMM')}
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1" className={hasError ? styles.error_message : undefined}>
          {t.creators.show.selectDate}
        </Typography>
      )}
    </Paper>
  );
};

export default ShowStartTimePicker;
