import ResponsiveTooltip from '../../../../components/ResponsiveTooltip/ResponsiveTooltip';

const taxInfoExplanation = `Wenn Du Kleinunternehmer bist und noch keine USt-IdNr. hast, kannst Du diese auch zu einem späteren Zeitpunkt nachtragen.

Du bist Kleinunternehmer, wenn der im Jahr der Gründung auf das Kalenderjahr hochgerechnete erwartete Gesamtumsatz Deines Unternehmens nicht mehr als 22.000 € beträgt. Bei unterjähriger Gründung gilt die Umsatzgrenze für das sogenannte Rumpfjahr (22.000 € : 12 Monate x Anzahl der Monate in denen Du tätig bist). Im Folgejahr bist Du Kleinunternehmer, wenn der Gesamtumsatz Deines Unternehmens im Vorjahr unter 22.000 € lag und Du im aktuellen Kalenderjahr voraussichtlich nicht mehr als 50.000 € Gesamtumsatz mit Deinem Unternehmen erzielst.

Du bist verpflichtet, Deinen Status als Kleinunternehmer entsprechend anzupassen und uns darüber zu informieren, sobald der Umsatz Deines Unternehmens über einem dieser Schwellenwerte liegt. Anschließend werden wir deine Gutschriftsrechnungen für das aktuelle Kalenderjahr entsprechend korrigieren. Für Fragen wende dich bitte an deinen steuerlichen Berater.`;

const TaxInfoIcon = () => {
  return <ResponsiveTooltip title={taxInfoExplanation} />;
};

export default TaxInfoIcon;
