import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';

const DesktopCustomizedTooltip = withStyles({
  tooltip: {
    maxWidth: '600px',
    whiteSpace: 'pre-wrap',
    fontSize: 12,
  },
})(Tooltip);

interface TaxInfoTooltipProps {
  title: string;
}

const DesktopTooltip = ({ title }: TaxInfoTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DesktopCustomizedTooltip
      PopperProps={{
        disablePortal: true,
      }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      title={<p>{title}</p>}
      placement="right"
    >
      <IconButton
        data-testid="desktop-info-icon-with-btn"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <InfoIcon />
      </IconButton>
    </DesktopCustomizedTooltip>
  );
};

export default DesktopTooltip;
