import { ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserIsCreator } from '../../auth/state/authSlice';
import { selectCreatorHasCompletedProfile } from '../../creators/state/creatorsSlice';
import Drawer from '../Drawer/Drawer';
import PageContainer from '../PageContainer/PageContainer';

export type NavItem = { text: string; icon?: JSX.Element };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    list: {
      paddingTop: 0,
    },
  })
);

interface Props {
  navItems: NavItem[];
  handleIndexChange: (selectedIndex: number) => JSX.Element;
  selectedIndex: number;
}

const SideNavigationWithContent = ({ navItems, handleIndexChange, selectedIndex }: Props) => {
  const classes = useStyles();
  const [renderedContent, setRenderedContent] = useState(handleIndexChange(selectedIndex));

  const userIsCreator = useSelector(selectUserIsCreator);
  const streamerHasCompletedProfile = useSelector(selectCreatorHasCompletedProfile);
  const isStreamerHasUncompletedProfile = userIsCreator && !streamerHasCompletedProfile;

  const handleNavigationChange = (event: MouseEvent<HTMLDivElement>, index: number) => {
    handleIndexChange(index);
  };

  useEffect(() => {
    setRenderedContent(handleIndexChange(selectedIndex));
  }, [selectedIndex]);

  const sideNavItems = (
    <List className={classes.list}>
      {navItems.map((item, index) => (
        <ListItemButton
          key={item.text}
          selected={selectedIndex === index}
          onClick={event => handleNavigationChange(event, index)}
          disabled={isStreamerHasUncompletedProfile}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </List>
  );

  return (
    <div className={classes.root} data-testid="side navigation">
      <Drawer navItems={sideNavItems} />
      <PageContainer pageToRender={renderedContent} />
    </div>
  );
};

export default SideNavigationWithContent;
