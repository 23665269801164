import {
  CountryCode,
  getCountryCodeFromStringOrDeDefault,
  TProductTileProduct,
} from '@hse24/shared-components';
import { ProductTileInfo } from '../model/productTileInfo';

export const mapProductTileInfoToProductTileProduct = (
  productTileInfo: ProductTileInfo
): TProductTileProduct => {
  return {
    ...productTileInfo,
    price: {
      ...productTileInfo.price,
      countryCode: getCountryCodeFromStringOrDeDefault(
        productTileInfo.price.countryCode,
        CountryCode.DE
      ),
    },
  };
};
