import { Divider, Grid } from '@mui/material';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys, profilePersonalDataLabels } from '../ProfilePersonalData';
import styles from './ProfileDataForm.module.scss';

const ProfileImageSelection = lazy(() => import('../ProfileImageSelection/ProfileImageSelection'));

const ProfileDataForm = () => {
  const profileData = useSelector(selectLoggedInCreator);

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item xs={12}>
        <h3 className={styles.heading}>1. Mein HELLO Profil</h3>
      </Grid>
      <Grid item>
        <ProfileImageSelection />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          value={profileData?.name}
          fieldName={profilePersonalDataKeys.name}
          label={`${profilePersonalDataLabels.name}*`}
          muiProps={{ inputProps: { maxLength: 30 } }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          value={profileData?.description || ''}
          fieldName={profilePersonalDataKeys.description}
          label={`${profilePersonalDataLabels.description}*`}
          muiProps={{ multiline: true, rows: 3, inputProps: { maxLength: 70 } }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default ProfileDataForm;
