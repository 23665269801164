import { Divider, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { LoggedInCreator } from '../../../model/creator';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import BicTextField from '../BicTextfield/BicTextField';
import NotLiableToVatCheckbox from '../NotLiableToVatCheckbox/NotLiableToVatCheckbox';
import ProfileLockInfoIcon from '../ProfileLockInfoIcon/ProfileLockInfoIcon';
import { profilePersonalDataKeys, profilePersonalDataLabels } from '../ProfilePersonalData';
import TaxInfoIcon from '../TaxInfoIcon/TaxInfoIcon';
import ValidatedIbanTextField from '../ValidatedIbanTextField/ValidatedIbanTextField';
import styles from './BusinessDataForm.module.scss';

const BusinessDataForm = () => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue, values } = useFormikContext<any>();

  const profileData: LoggedInCreator | undefined = useSelector(selectLoggedInCreator);
  useEffect(() => {
    setFieldValue(profilePersonalDataKeys.accountHolderName, getOwnerValueFromState(), false);
  }, [profileData?.firstName, profileData?.lastName]);

  function getOwnerValueFromState() {
    if (profileData?.firstName && profileData.lastName) {
      return `${profileData?.firstName} ${profileData?.lastName}`;
    } else {
      return 'automatisch befüllt';
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3 className={styles.heading}>
          3. Konto- und Geschäftsdaten
          <ProfileLockInfoIcon />
        </h3>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.accountHolderName}
          label={profilePersonalDataLabels.accountHolderName}
          value={getOwnerValueFromState()}
          muiProps={{ disabled: true }}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <ValidatedIbanTextField />
      </Grid>
      <Grid item xs={12} sm={4}>
        <BicTextField />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.taxId}
          label={`${profilePersonalDataLabels.taxId}*`}
          value={profileData?.taxId}
        />
      </Grid>
      <Grid container alignItems={'center'} justifyContent={'flex-start'}>
        <Grid item xs={12}>
          <NotLiableToVatCheckbox />
          <TaxInfoIcon />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.vatId}
          label={
            values.isLiableToVat
              ? `${profilePersonalDataLabels.vatId}*`
              : profilePersonalDataLabels.vatId
          }
          value={profileData?.vatId}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default BusinessDataForm;
