import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { useDispatch, useSelector } from 'react-redux';
import DateFilterInFuture, {
  FutureDateFilters,
} from '../../../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import t from '../../../constants/translation';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import AdminPlannedShowsList from '../../component/AdminPlannedShowsList/AdminPlannedShowsList';
import { StreamedBy } from '../../model/shows';
import {
  selectAdminUpcomingShowsQueryData,
  setAdminUpcomingShowsQueryData,
} from '../../state/showsSlice';
import styles from './NewUpcomingShowsOverviewPage.module.scss';

const NewUpcomingShowsOverviewPage = () => {
  const AdminUpcomingShowsOverview = () => {
    const queryData = useSelector(selectAdminUpcomingShowsQueryData);

    const dispatch = useDispatch();

    const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          dateFrom: dateRangeWithSelectedFilter.startDate,
          dateTo: dateRangeWithSelectedFilter.endDate,
          dateFilter: dateRangeWithSelectedFilter.dateFilter as FutureDateFilters,
        })
      );

    const toggleExcludeEarlyBirdShowsChanged = () =>
      dispatch(
        setAdminUpcomingShowsQueryData({
          excludeEarlyBirds: !(queryData.excludeEarlyBirds ?? false),
        })
      );

    const toggleStreamedByHSEOnly = () => {
      return dispatch(
        setAdminUpcomingShowsQueryData({
          streamedBy: (queryData.streamedBy ?? [])?.includes(StreamedBy.HSE)
            ? []
            : [StreamedBy.HSE],
        })
      );
    };

    return (
      <div className={styles.container}>
        <span className={styles.title}>{t.admins.upcomingShowsOverview['Upcoming Streams']}</span>
        <div className={styles.table_filter}>
          <div>
            <div className={styles.quick_filters_label}>Quick filter</div>
            <div className={styles.quick_filters_wrapper}>
              <Button
                className={styles.quick_filters_button}
                size={EButtonSize.SMALL}
                type={
                  queryData.excludeEarlyBirds === true ? EButtonType.PRIMARY : EButtonType.BLANK
                }
                onClick={toggleExcludeEarlyBirdShowsChanged}
              >
                {t.creators.show.hideEarlyShopping}
              </Button>
              <Button
                className={styles.quick_filters_button}
                size={EButtonSize.SMALL}
                type={
                  queryData.streamedBy?.length == 1 && queryData.streamedBy.includes(StreamedBy.HSE)
                    ? EButtonType.PRIMARY
                    : EButtonType.BLANK
                }
                onClick={toggleStreamedByHSEOnly}
              >
                {t.creators.show.streamedByHSEOnly}
              </Button>
            </div>
          </div>
          <div>
            <DateFilterInFuture
              onDateFiltersChange={onDateFiltersChange}
              selectedFilter={queryData.dateFilter}
            />
          </div>
        </div>
        <div className={styles.shows_wrapper}>
          <AdminPlannedShowsList />
        </div>
      </div>
    );
  };
  return (
    <PageContainer backgroundColor={theme.whiteLilac} pageToRender={AdminUpcomingShowsOverview()} />
  );
};

export default NewUpcomingShowsOverviewPage;
