import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import ClickableDetailsRow from '../../../../components/DetailsRow/ClickableDetailsRow/ClickableDetailsRow';
import ResponsiveDetailsRow from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import { displayName } from '../../../../creators/model/creator';
import { navigateToCreatorDetailsPage } from '../../../../creators/state/creatorsSlice';
import { CreatorDetails } from '../../../model/shows';
import styles from './CreatorInfo.module.scss';

interface CreatorInfoProps {
  creatorDetails: CreatorDetails;
}

const CreatorInfo = ({ creatorDetails }: CreatorInfoProps) => {
  const dispatch = useDispatch();

  const navigateToCreatorDetails = () =>
    dispatch(navigateToCreatorDetailsPage({ creatorId: creatorDetails.id }));

  return (
    <div data-testid={'creator-info'}>
      <Box className={styles.creator_info_header}>
        <AccountBoxIcon className={styles.creator_icon} />
        <Typography fontWeight="700">Streamer</Typography>
      </Box>
      <ResponsiveDetailsRow label="ID" value={creatorDetails.id} withCopyIcon />
      <ClickableDetailsRow
        label="Name"
        value={creatorDetails.name}
        onClick={navigateToCreatorDetails}
        className={styles.creator_name}
      />
      <ResponsiveDetailsRow label="Rolle" value={displayName(creatorDetails.role)} last />
    </div>
  );
};

export default CreatorInfo;
