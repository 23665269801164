export const addPathQuery = (path: string, query: Record<string, string>) => {
  return path + '?' + new URLSearchParams(query).toString();
};

export const secondsIntoMinutes = (seconds: number) => Math.round(seconds / 60) || 1;

// 86400 1 day
export const setCookie = (
  name: string,
  value: string,
  secure = true,
  maxAge = 86400,
  path = '/'
) => {
  if (!document.cookie.includes(`${name}=`)) {
    const cookie = `${name}=${value}; max-age=${maxAge}; path =${path}`;
    document.cookie = secure ? cookie.concat('; secure') : cookie;
  }
};
