import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import AudienceBadge from '../../../components/AudienceBadge/AudienceBadge';
import ServerSideDataGrid, {
  Pageable,
} from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import { displayName } from '../../../creators/model/creator';
import routePaths from '../../../routes/routePaths';
import theme from '../../../theme/theme.module.scss';
import { EUROPE_BERLIN_TIMEZONE, START_SHOW_FORMAT } from '../../constants';
import { selectAdminUpcomingShows } from '../../state/showsSlice';
import PlannedShowsMenuOptions from '../PlannedShowsMenuOptions/PlannedShowsMenuOptions';
import StreamedByWithStreamNameCell from '../StreamedByWithStreamNameCell/StreamedByWithStreamNameCell';
import VoucherBadge from '../VoucherBadge/VoucherBadge';

dayjs.extend(utc);
dayjs.extend(timezone);

interface AdminUpcomingShowsTableProps {
  pageable: Pageable;
  pageableCallback: (pageable: Pageable) => void;
}

const PlannedShowsTable = ({ pageable, pageableCallback }: AdminUpcomingShowsTableProps) => {
  const showsQuery = useSelector(selectAdminUpcomingShows);
  const history = useHistory();
  const isHSEEmployee = useSelector(selectUserIsHSEEmployee);
  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Stream',
      minWidth: 250,
      renderCell: ({ row }) => (
        <StreamedByWithStreamNameCell
          streamedBy={row.streamedBy}
          title={row.title}
          isPublished={row.isPublished}
        />
      ),
    },
    {
      field: 'scheduledAt',
      headerName: 'Datum',
      width: 200,
      type: 'date',
      valueFormatter: ({ value }) =>
        dayjs(value).tz(EUROPE_BERLIN_TIMEZONE).format(START_SHOW_FORMAT),
    },
    {
      field: 'creatorName',
      headerName: 'Streamer',
      width: 200,
      valueGetter: params => params.row.creator.name,
    },
    {
      field: 'audience',
      headerName: 'Audience',
      minWidth: 145,
      renderCell: value => <AudienceBadge audience={value.row.audience} />,
    },
    {
      field: 'creatorRole',
      headerName: 'Rolle',
      width: 100,
      valueGetter: params => params.row.creator.role,
      valueFormatter: ({ value }) => displayName(value),
      hide: true,
    },
    {
      field: `voucherCode`,
      headerName: 'Gutschein',
      width: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <VoucherBadge voucherCode={params.row.voucherCode}></VoucherBadge>,
      valueGetter: ({ value }) => (value != undefined ? value : '-'),
    },
    {
      field: 'numberOfProducts',
      headerName: 'Produkte',
      width: 200,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'menu',
      headerName: '',
      width: 70,
      sortable: false,
      renderCell: ({ row }) => <PlannedShowsMenuOptions showId={row.id} />,
      flex: 1,
      align: 'right',
      hide: !isHSEEmployee,
    },
  ];

  const navigateToShowDetails = (rowParams: GridRowParams) => {
    const path = generatePath(routePaths.hseEmployee.showDetails, { showId: rowParams.row.id });
    history.push(path);
  };

  return (
    <ServerSideDataGrid
      columns={columns}
      rows={showsQuery.shows}
      rowCount={showsQuery.hits}
      loading={showsQuery.loading}
      rowsPerPageOptions={[10, 25, 50]}
      autoHeight={true}
      quickFilterPlaceholder={'Nach Stream suchen'}
      updatePageableCallback={pageableCallback}
      initialPageable={pageable}
      onRowClick={isHSEEmployee ? navigateToShowDetails : undefined}
      getRowClassName={params => `published-show-row-theme--${params.row.isPublished}`}
      sx={{
        '& .published-show-row-theme--false': {
          color: theme.grayDark,
          '&:hover': {
            color: theme.grayDark,
          },
        },
      }}
    />
  );
};

export default PlannedShowsTable;
