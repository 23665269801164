import { Button, EButtonType, IconCross } from '@hse24/shared-components';
import {
  Box,
  CardContent,
  Dialog,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../src/constants/translation';
import { selectUserIsClassicStreamer } from '../../auth/state/authSlice';
import { showErrorDialog } from '../../notifications/state/notificationsSlice';
import {
  Aspect,
  createImage,
  cropImage,
  CropSource,
  getOutputResolution,
} from '../../utils/images/cropImageFns';
import styles from './CropModal.module.scss';

interface Props {
  handleCancelCrop: () => void;
  imageUrl: string;
  onSaveHandler: (image: Blob) => void;
  cropSource: CropSource;
  aspect?: Aspect;
  title: string;
  description: string;
}

function CropModal({
  handleCancelCrop,
  imageUrl,
  onSaveHandler,
  cropSource,
  title,
  aspect = Aspect.RATIO_9_16,
}: Props) {
  const outputResolution = getOutputResolution(aspect);
  const cropShape = cropSource === CropSource.PROFILE ? 'round' : 'rect';

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [crop, setCrop] = useState({ x: 2, y: 2 });
  const [zoom, setZoom] = useState(1);
  const [isCropBtnDisabled, setCropBtnDisabled] = useState(false);
  const [croppedArea, setCroppedArea] = useState<Area>({ width: 0, height: 0, x: 0, y: 0 });
  const dispatch = useDispatch();

  const cropRef = useRef<Cropper>(null);
  const isClassicStreamer = useSelector(selectUserIsClassicStreamer);

  useEffect(() => {
    if (cropSource !== CropSource.STREAM || !isClassicStreamer) {
      return;
    }
    const container = cropRef?.current?.containerRef;
    const cropperArea = container?.childNodes[1] as HTMLDivElement;
    if (container && cropperArea) {
      const htmlDivElement = document.createElement('div');
      htmlDivElement.className = styles.overlay;
      cropperArea.appendChild(htmlDivElement);
    }
  }, [cropRef?.current?.containerRef?.childNodes[1]]);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) =>
    setCroppedArea(croppedAreaPixels);

  const saveCroppedImage = async () => {
    setCropBtnDisabled(true);
    try {
      const image = await createImage(imageUrl);
      if (image) {
        const croppedImage = await cropImage(image, croppedArea, outputResolution);
        setCropBtnDisabled(false);
        if (croppedImage) {
          onSaveHandler(croppedImage);
        } else {
          dispatch(showErrorDialog('Bitte versuche es nochmal'));
        }
      }
    } catch (error) {
      setCropBtnDisabled(false);
      return null;
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth={'sm'}
        open={Boolean(imageUrl)}
        onClose={handleCancelCrop}
        data-testid={'cropper-modal'}
        PaperProps={
          !fullScreen
            ? {
                className: styles.dialog_paper,
              }
            : {}
        }
      >
        <Box className={styles.dialog}>
          <Box className={styles.title_container}>
            <div onClick={handleCancelCrop}>
              <IconCross className={styles.cancel} width={'24px'} height={'24px'} />
            </div>
            <Typography className={styles.title}>{title}</Typography>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.crop} style={{ aspectRatio: `${aspect}` }}>
              <CardContent className={styles.crop_playground}>
                <Cropper
                  ref={cropRef}
                  image={imageUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  cropShape={cropShape}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  classes={{
                    containerClassName: styles.cropper_container,
                    mediaClassName: styles.cropper_media,
                    cropAreaClassName: styles.crop_aria,
                  }}
                />
              </CardContent>{' '}
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Box className={styles.crop_zoom_slider}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom as number)}
                color={'secondary'}
                className={styles.slider}
              />
            </Box>
            {isClassicStreamer && (
              <Typography className={styles.hint}>{t.creators.show.CropHint}</Typography>
            )}
            <Box
              className={styles.buttons}
              p={2}
              display={'flex'}
              alignItems={'center'}
              flexWrap={'wrap'}
              gap={'1rem'}
            >
              <Box className={styles.button}>
                <Button type={EButtonType.BLANK} onClick={handleCancelCrop}>
                  {t.common.Cancel}
                </Button>
              </Box>
              <Box className={styles.button}>
                <Button disabled={isCropBtnDisabled} onClick={saveCroppedImage}>
                  Auswählen
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default CropModal;
