import { MediaType } from '../api/postsRequestResponse';

export interface PostImageMetadata {
  fileExtension: string;
  imageUrl: string;
  mediaType?: MediaType;
}

export interface PostData {
  preview: PostImageMetadata;
  caption: string;
  baseProductsNo: string[];
  scheduleAt?: string;
}

export interface StreamPostData {
  preview?: PostImageMetadata | null;
  caption?: string | null;
  scheduleAt?: string;
}

export interface Post {
  id: string;
  caption: string;
  imageUrl: string;
  likes: number;
  shares: number;
  commentsCount: number;
  createdAt: string;
  status: PostStatus;
  scheduleAt?: string;
}

export enum PostStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED_HSE_EMPLOYEE = 'UNPUBLISHED_HSE_EMPLOYEE',
  UNPUBLISHED_CREATOR = 'UNPUBLISHED_CREATOR',
}
