import { LiveTv } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import PageWithNavigation from '../../../layout/PageWithNavigation/PageWithNavigation';
import { NavItem } from '../../../layout/SideNavigationWithContent/SideNavigationWithContent';
import StreamingSettings from '../../../shows/component/StreamingSettings/StreamingSettings';
import StreamerPersonalDataComponent from '../../components/Profile/StreamerPersonalDataComponent';
import CreatorAccountPage from '../CreatorAccountPage/CreatorAccountPage';

interface SettingsItem {
  navItem: NavItem;
  component: JSX.Element;
}

const CreatorProfile = () => {
  const navItems: SettingsItem[] = [
    {
      navItem: {
        text: 'Persönliche Daten',
        icon: <PersonIcon data-testid="person-icon" />,
      },
      component: <StreamerPersonalDataComponent />,
    },
    {
      navItem: {
        text: 'Mein Account',
        icon: <ManageAccountsIcon />,
      },
      component: <CreatorAccountPage />,
    },
    {
      navItem: {
        text: 'Streamingeinstellungen',
        icon: <LiveTv data-testid="streaming-icon" />,
      },
      component: <StreamingSettings />,
    },
  ];

  const renderContent = (selectedIndex: number) => {
    return navItems[selectedIndex].component;
  };

  return (
    <PageWithNavigation renderContent={renderContent} navItems={navItems.map(i => i.navItem)} />
  );
};

export default CreatorProfile;
