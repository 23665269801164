import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import theme from '../../../theme/theme.module.scss';
import { changeWhiteSpacesToHTMLFormat } from '../../../utils/formatters/htmlFormatter/htmlFormatter';
import CreateEditPostForm from '../../components/CreateEditPostForm/CreateEditPostForm';
import CreatePostHeader from '../../components/CreatePostHeader/CreatePostHeader';
import { StreamPostData } from '../../model/post';
import { createNewPost } from '../../state/postsSlice';

const CreatePostView = () => {
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(selectProductSelection);

  useEffect(() => {
    dispatch(clearProductsSelection());
  }, []);

  const onPublishPost = ({ preview, caption, scheduleAt }: StreamPostData) => {
    if (preview && caption?.trim()) {
      dispatch(
        createNewPost({
          preview,
          baseProductsNo: selectedProducts.map(p => p.baseProductNo),
          caption: changeWhiteSpacesToHTMLFormat(caption),
          scheduleAt,
        })
      );
    }
  };

  return (
    <>
      <CreatePostHeader title="Post erstellen" />
      <CreateEditPostForm isCreate submitCallback={onPublishPost} />
    </>
  );
};

function CreatePostPage() {
  return (
    <PageContainer backgroundColor={theme.white} pageToRender={<CreatePostView />} fullHeight />
  );
}

export default CreatePostPage;
