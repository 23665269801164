import { Button, EButtonSize } from '@hse24/shared-components';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import { Box, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';
import ResponsiveDetailsRow from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import SecureDetailsRow from '../../../../components/DetailsRow/SecureDetailsRow/SecureDetailsRow';
import ResponsiveTooltip from '../../../../components/ResponsiveTooltip/ResponsiveTooltip';
import routePaths from '../../../../routes/routePaths';
import { ShowDetails } from '../../../model/shows';
import styles from './ExternalDeviceInfo.module.scss';

const germanTooltip = `Videoformat
Derzeit unterstützen wir Live Video Shopping nur im Format (9:16).

Auflösung
Die maximal zulässige Auflösung beträgt 1080p.

HDR
HDR-Videoformate werden nicht unterstützt.

Frame rate
Das Maximum beträgt 60fps.

Audio
Derzeit werden maximal 2 Audiokanäle unterstützt. 

Sonstiges
Für jede erstellte Show wird ein neuer Stream Key generiert.`;

interface ExternalDeviceInfoProps {
  showDetails: ShowDetails;
}

const ExternalDeviceInfo = ({ showDetails }: ExternalDeviceInfoProps) => {
  const history = useHistory();

  const navigateToShowModeration = () => {
    const path = generatePath(routePaths.hseEmployee.showModeration, { showId: showDetails.id });
    history.push(path);
  };

  return (
    <div data-testid="external-device-info">
      <Box className={styles.info_header}>
        <AppSettingsAltIcon className={styles.icon} />
        <Typography fontWeight="700">
          Externe Geräteeinstellungen <ResponsiveTooltip title={germanTooltip} />
        </Typography>
      </Box>
      <ResponsiveDetailsRow
        label="Stream URL"
        value={showDetails.streamingUrl ?? '-'}
        withCopyIcon
      />
      {showDetails.streamingKey ? (
        <SecureDetailsRow label="Stream Key" value={showDetails.streamingKey} last />
      ) : (
        <ResponsiveDetailsRow label="Stream Key" value={'-'} last />
      )}
      <Button
        onClick={() => navigateToShowModeration()}
        style={{ width: 'auto' }}
        size={EButtonSize.SMALL}
      >
        <Typography fontWeight={700}>Zur Moderationsseite</Typography>
      </Button>
    </div>
  );
};

export default ExternalDeviceInfo;
