import { addDays, format, isAfter, isBefore, startOfDay, startOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
import dayjs from 'dayjs';
import { EUROPE_BERLIN_TIMEZONE } from '../shows/constants';

require('dayjs/locale/de');

export const daysInGerman = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

export interface DayDetails {
  date: Date;
  dayName: string;
  formattedDayNumber: string;
}

export interface TimeDetails {
  hours: string;
  minutes: string;
}

export const germanMonth = (date: Date): string => {
  return format(date, 'MMMM', { locale: de });
};

export const germanDay = (date: Date): string => {
  return format(date, 'EEEE', { locale: de });
};

export const formatDay = (date: Date, dayFormat = 'd') => {
  return format(date, dayFormat);
};

export const formatYear = (date: Date, yearFormat = 'yyyy') => {
  return format(date, yearFormat);
};

export const formatHour = (date: Date, hourFormat = 'HH:mm') => {
  return format(date, hourFormat);
};

export const isWeekSpanningTwoMonths = (startOfTheWeek: Date, endOfTheWeek: Date): boolean => {
  return !(startOfTheWeek.getMonth() === endOfTheWeek.getMonth());
};

export const getWeekDetails = (currentDate: Date, dayFormat = 'd'): DayDetails[] => {
  const firstDayOfWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  return daysInGerman.map((day, index) => {
    const currentDay = addDays(firstDayOfWeek, index);
    return { dayName: day, formattedDayNumber: format(currentDay, dayFormat), date: currentDay };
  });
};

export const isBeforeToday = (date: Date) => {
  return isBefore(startOfDay(date), startOfDay(new Date()));
};

export const isAfterToday = (date: Date) => {
  return isAfter(date, new Date());
};

export const isValidHour = (hour: string) => {
  const hourPattern = /^(0[0-9]|1[0-9]|2[0-3])$/;
  return hourPattern.test(hour);
};
export const isValidMinutes = (minutes: string) => {
  const minutesPattern = /^[0-5][0-9]$/;
  return minutesPattern.test(minutes);
};

export const toBerlinTimeZone = (date: Date) => dayjs(date).tz(EUROPE_BERLIN_TIMEZONE, true);
