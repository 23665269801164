import { IconWithText } from '@hse24/shared-components';
import { Box, ListItem, Radio, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { FormEvent } from 'react';
import SubmitButton from '../../../../components/Button/SubmitButton';
import { StreamedBy } from '../../../model/shows';
import { Option } from './StreamedByInfo';
import styles from './StreamedByInfo.module.scss';

interface Props {
  options: Option[];
  selectedValue: StreamedBy;
  handleClick: (value: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const StreamedByInfoEditable = ({ options, selectedValue, handleClick, handleSubmit }: Props) => {
  return (
    <Box
      data-testid={'streamed-by-container'}
      className={styles.streamed_by_container}
      sx={{ my: 3 }}
    >
      <Box data-testid={'content'}>
        <Typography fontWeight={'600'} mt={'3px'} sx={{ fontSize: '16px' }}>
          Stream-Typ:
        </Typography>
        <form onSubmit={handleSubmit}>
          <List>
            {options.map(option => (
              <ListItem key={option.value} onClick={() => handleClick(option.value)}>
                <ListItemIcon>
                  <Radio
                    checked={selectedValue === option.value}
                    value={option.value}
                    inputProps={{ 'aria-label': `radio-button-${option.value}` }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <IconWithText icon={option.icon} style={{ alignItems: 'center' }}>
                    <Typography variant="body1">{option.primaryText}</Typography>
                    <Typography variant="body2">{option.secondaryText}</Typography>
                  </IconWithText>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <SubmitButton type="submit" className={styles.submit_btn}>
            Speichern
          </SubmitButton>
        </form>
      </Box>
    </Box>
  );
};

export default StreamedByInfoEditable;
