import { Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import FormikSelect from '../../../../components/FormikSelect/FormikSelect';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import BirthdatePicker from '../BirthdatePicker/BirthdatePicker';
import ProfileLockInfoIcon from '../ProfileLockInfoIcon/ProfileLockInfoIcon';
import {
  profilePersonalDataKeys,
  profilePersonalDataLabels,
  Salutation,
  translate,
} from '../ProfilePersonalData';
import styles from './ContactDataForm.module.scss';

const ContactDataForm = () => {
  const profileData = useSelector(selectLoggedInCreator);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3 className={styles.heading}>
          2. Kontaktdaten <ProfileLockInfoIcon />
        </h3>
        <Typography>
          Die hier eingetragenen Daten müssen mit denen aus der umsatzsteuerlichen Registrierung
          übereinstimmen, insofern du kein Kleinunternehmer bist.
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <FormikSelect
          fieldName={profilePersonalDataKeys.salutation}
          label={`${profilePersonalDataLabels.salutation}*`}
          value={profileData?.salutation === Salutation.MR ? Salutation.MR : Salutation.MS}
          menuItems={Object.values(Salutation).map(value => (
            <MenuItem key={value} value={value}>
              {translate(value)}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={8} sm={5}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.firstName}
          label={`${profilePersonalDataLabels.firstName}*`}
          value={profileData?.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.lastName}
          label={`${profilePersonalDataLabels.lastName}*`}
          value={profileData?.lastName}
        />
      </Grid>
      <Grid item xs={8} sm={8} md={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.street}
          label={`${profilePersonalDataLabels.street}*`}
          value={profileData?.street}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={2}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.streetNumber}
          label={`${profilePersonalDataLabels.streetNumber}*`}
          value={profileData?.streetNumber}
        />
      </Grid>
      <Grid item xs={5} sm={4} md={2}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.zipCode}
          label={`${profilePersonalDataLabels.zipCode}*`}
          value={profileData?.zipCode}
        />
      </Grid>
      <Grid item xs={7} sm={8} md={4}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.city}
          label={`${profilePersonalDataLabels.city}*`}
          value={profileData?.city}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={3}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.phoneNumber}
          label={profilePersonalDataLabels.phoneNumber}
          value={profileData?.phoneNumber}
        />
      </Grid>
      <Grid container item xs={12} sm={5} md={3}>
        <BirthdatePicker />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikTextField
          fieldName={profilePersonalDataKeys.email}
          label={profilePersonalDataLabels.email}
          value={profileData?.email}
          muiProps={{ disabled: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default ContactDataForm;
