import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../../constants/translation';
import { useClickOutside } from '../../../../utils/clickOutsideUtil';
import { Voucher } from '../../../model/voucher';
import {
  attachVoucherToShow,
  detachVoucherFromShow,
  selectShowDetails,
  selectShowDetailsVoucher,
} from '../../../state/showsSlice';
import styles from './VoucherDetails.module.scss';
import VoucherRestrictions from './VoucherRestrictions/VoucherRestrictions';

interface Props {
  isVoucherFormDisabled: boolean;
  voucher?: Voucher;
  showId: string;
}

const VoucherDetails = ({ isVoucherFormDisabled, voucher, showId }: Props) => {
  const { data } = useSelector(selectShowDetails);
  const [currentVoucher, setCurrentVoucher] = useState<Voucher | undefined>(voucher);
  const [code, setCode] = useState<string>(currentVoucher?.code ?? '');
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(true);
  const { loading, errorKey } = useSelector(selectShowDetailsVoucher);
  const dispatch = useDispatch();
  const clickRef = useRef();
  useClickOutside(clickRef, () => !!code.length && setIsButtonVisible(false));

  useEffect(() => {
    setCurrentVoucher(data?.vouchers?.[0] || undefined);
    setCode(data?.vouchers?.[0]?.code ?? '');
  }, [data]);

  const clearVoucher = () => {
    dispatch(detachVoucherFromShow({ showId }));
    setCurrentVoucher(undefined);
    setCode('');
    setIsButtonVisible(false);
  };

  const validateVoucherCode = () => {
    setHasError(true);
    if (code?.trim() != '') {
      dispatch(
        attachVoucherToShow({
          showId,
          voucherCode: code,
        })
      );
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);

    if (currentVoucher != undefined) {
      setCurrentVoucher({
        ...currentVoucher,
        code: event.target.value,
      });
    }
  };
  return (
    <Box mt={2} mb={2}>
      <Box className={styles.voucher_info_header}>
        <Typography fontWeight="700">{t.admins.Vouchers.Voucher}</Typography>
      </Box>
      <Box mb={1} mt={3} color="#595964">
        {
          t.admins.Vouchers[
            'After adding a voucher code, it is automatically advertised in the stream.'
          ]
        }
      </Box>
      <Box className={styles.voucher_form} ref={clickRef}>
        <TextField
          error={
            (!!errorKey && hasError) ||
            ((!code.length || code !== voucher?.code) &&
              !isButtonVisible &&
              voucher?.code != undefined)
          }
          id="voucher-code"
          placeholder={t.admins.Vouchers['Enter voucher code']}
          helperText={
            errorKey && hasError
              ? //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                (t.admins.Vouchers.errorKeys[errorKey] as string)
              : (!code.length || code !== voucher?.code) &&
                !isButtonVisible &&
                voucher?.code != undefined
              ? !loading && t.admins.Vouchers.errorKeys['empty voucher']
              : null
          }
          variant="outlined"
          size="small"
          className={styles.voucher_text_field}
          value={code}
          onChange={handleInputChange}
          onFocus={() => setIsButtonVisible(true)}
          disabled={isVoucherFormDisabled || loading}
          InputProps={{
            endAdornment: !errorKey ? (
              <InputAdornment position="end" onClick={() => setIsButtonVisible(true)}>
                {code === voucher?.code ? (
                  <CheckIcon className={styles.check_icon_button_input} />
                ) : (
                  voucher != undefined &&
                  !loading &&
                  code.length != 0 && (
                    <IconButton
                      onClick={() => {
                        setCode('');
                      }}
                    >
                      <CloseIcon className={styles.close_icon_button_input} />
                    </IconButton>
                  )
                )}
              </InputAdornment>
            ) : (
              !!code.length && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setCode('');
                      setHasError(false);
                    }}
                  >
                    <CloseIcon className={styles.close_icon_button_input} />
                  </IconButton>
                </InputAdornment>
              )
            ),
          }}
        />

        {!isVoucherFormDisabled && isButtonVisible && (
          <>
            <Tooltip title={t.common.Cancel} placement={'bottom'}>
              <IconButton
                data-testid="delete"
                aria-label="voucher-delete-btn"
                className={styles.close_icon_button}
                onClick={clearVoucher}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t.common.Save} placement={'bottom'}>
              <IconButton
                aria-label="voucher-check-btn"
                className={styles.check_icon_button}
                onClick={validateVoucherCode}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      <Box mt={2}>
        <VoucherRestrictions voucher={!!errorKey ? undefined : voucher} isLoading={loading} />
      </Box>
    </Box>
  );
};
export default VoucherDetails;
